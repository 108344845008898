//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'auth',
  middleware: ['guest'],

  head: () => {
    return {
      title: 'Login - Paninti Store',
      meta: [{
        hid: 'description',
        name: 'description',
        content: 'Hallo parents ! Bergabunglah sekarang dengan Paninti.com'
      }]
    }
  }
}
