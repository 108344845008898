import { render, staticRenderFns } from "./InformationProductSection.vue?vue&type=template&id=5abf8e68&scoped=true&"
import script from "./InformationProductSection.vue?vue&type=script&lang=js&"
export * from "./InformationProductSection.vue?vue&type=script&lang=js&"
import style0 from "./InformationProductSection.vue?vue&type=style&index=0&id=5abf8e68&lang=scss&scoped=true&"
import style1 from "./InformationProductSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5abf8e68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Divider: require('/usr/src/app/components/base/Divider.vue').default,IconBoxProcessed: require('/usr/src/app/components/icons/IconBoxProcessed.vue').default})
