//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'auth',
  middleware: ['guest'],
  computed: {},
  head: () => {
    return {
      title: 'Pendaftaran Akun Baru | Paninti Store'
    }
  }
}
