//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions } from 'vuex'

import { homePagePaths } from '@/utils/helpers/page-helper'
import {
  assignResponseToDataModelData,
  defaultPaginationAttributes,
  resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { affiliateProductsSortOptions } from '@/utils/helpers/affiliate-product-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'
import { toastTypes } from '~/utils/helpers/toast-helper'

export default {
  layout: 'account',
  middleware: ['user', 'affiliate'],

  data () {
    return {
      breadCrumb: [
        {
            path: homePagePaths.account
        },
        {
            name: 'Afiliasi',
            path: '/account/affiliate/'
        },
        {
            name: 'Daftar Produk Afiliasi'
        }
      ],

      // List Affiliate Products Filter
      listAffiliateProductsFilter: {
        search: '',
        sort: affiliateProductsSortOptions.latest.value
      },

      // Affiliate Products
      getAffiliateProductsData: {
        ...defaultPaginationAttributes(),
        perPage: 8
      },

      modalShareProductData: {
        name: '',
        share_link: '',
        share_text: '',
        files: [],
        is_affiliated: false,
        store: {
          name: ''
        }
      }
    }
  },

  computed: {
    affiliateProductsSortOptions () {
      return affiliateProductsSortOptions
    }
  },

  watch: {
    '$route.query': {
      handler (query) {
        this.resetAffiliateProductData()
      },
      deep: true
    }
  },

  mounted () {
    this.setAffiliateProductDataFromRoute()
  },

  methods: {
    ...mapActions('Product', ['getProductAffiliate']),

    generateRandomString,

    setAffiliateProductDataFromRoute () {
      this.listAffiliateProductsFilter.search = this.$route.query.affiliateProductSearch || ''
      this.listAffiliateProductsFilter.sort =
        this.$route.query.affiliateProductSort || affiliateProductsSortOptions.latest.value
    },

    resetAffiliateProductData () {
      this.$refs.catalogListAffiliateProducts.completeInfiniteLoadingListAffiliateProducts()

      resetPaginationData(this.getAffiliateProductsData, {
        perPage: this.getAffiliateProductsData.perPage,
        total: this.getAffiliateProductsData.total
      })

      this.$refs.catalogListAffiliateProducts.resetInfiniteLoadingListAffiliateProducts()
    },

    // Affiliate List Affiliate Products Header
    goToAddAffiliateProductPage () {
      this.$router.push('/account/affiliate/product-list/add-product');
    },

    // Affiliate List Affiliate Products Filter
    handleChangeInputSearch: _.debounce(function (searchValue) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          affiliateProductSearch: searchValue || null
        })
      })
    }, 700),

    handleChangeSelectSort (sortValue) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          affiliateProductSort: sortValue
        })
      })
    },

    // Catalog Affiliate Products
    async handleInfiniteLoadingGetUserAffiliateProducts ($state) {
      if (this.getAffiliateProductsData.page >= this.getAffiliateProductsData.totalPage) {
        $state.complete()
        return
      }

      await this.getUserAffiliateProducts()
      $state.loaded()
    },

    async getUserAffiliateProducts () {
      try {
        this.getAffiliateProductsData.loading = true

        const response = await this.getProductAffiliate({
          data: {
            paginate: this.getAffiliateProductsData.paginated,
            per_page: this.getAffiliateProductsData.perPage,
            page: this.getAffiliateProductsData.page + 1,
            ...filterNonNullValues({
              search: this.listAffiliateProductsFilter.search || null,
              order_by: this.listAffiliateProductsFilter.sort || this.affiliateProductsSortOptions.latest.value
            })
          }
        })

        assignResponseToDataModelData(
          this.getAffiliateProductsData,
          response.data.data.affiliate_products,
          false
        )
      } catch (error) {
        console.log(error)
        this.$refs.catalogListAffiliateProducts.completeInfiniteLoadingListAffiliateProducts()

        this.$message({
          message: 'Gagal memuat data produk afiliasi',
          type: toastTypes.error.value,
          showClose: true
        })
      } finally {
        this.getAffiliateProductsData.loading = false
      }
    },

    handleAffiliateProductDeleted (deletedData) {
      this.$message({
        message: `Produk ${deletedData.productName} berhasil dihapus dari daftar produk afiliasi`,
        type: toastTypes.success.value,
        showClose: true
      })

      this.resetAffiliateProductData()
    },

    handleBtnShareCardProductClicked (shareData) {
      this.modalShareProductData = {
        ...shareData
      }
    }
  }
}
