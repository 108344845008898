//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'account',
  middleware: ['user'],

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Alamat'
        }
      ]
    }
  }
}
