//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Pagination, AutoPlay } from '@egjs/flicking-plugins'

export default {
  data () {
    return {
      plugins: [new Pagination(), new AutoPlay({ duration: 3000 })],
      options: {
        circular: true,
        panelsPerView: 1,
        align: 'prev'
      },
      datas: [
        {
          img: require('@/static/img/auth/illustration-authentication-1.svg'),
          title: 'Brand Terbaik dan Produk Terbaik',
          content: 'Temukan brand terbaik untuk ibu, bayi dan keluargamu di Paninti'
        },
        {
          img: require('@/static/img/auth/illustration-authentication-2.svg'),
          title: 'Belanja lebih hemat dari Partner terdekat',
          content: 'Beli kebutuhan kecil dari toko-toko bayi yang tersedia di kotamu!'
        }
      ]
    }
  }
}
