//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins/index'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      imgSrc: '',
      target: 'cover_image',
      imgFile: '',
      customHeaders: {
        'Application-Key': 'Q5nGL97wRBFlgQ2WOG3XDnHhV33jFIYWdHKArumKS78YaSQA3U',
        Authorization: this.$auth.loggedIn ? this.$auth.getToken('local') : null
      },
      showUpload: true,
      dataCropper: {},
      fileList: [],
      formUser: {
        url: '',
        original: '',
        name: '',
        cropped: ''
      },
      disabled: false
    }
  },
  computed: {
    ...mapState('Account', ['isEdit']),
    ...mapState('Base', ['dataBreadCrumbs']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    },
    baseApi () {
      return process.env.BASE_API
    },
    haveCover () {
      if (this.user.files.length) {
        return this.user.files.filter((file) => {
          return file.name === 'cover_image' && file.variant === 'original'
        })
      }
      return false
    }
  },
  methods: {
    ...mapActions('Files', ['uploadFiles']),
    ...mapActions('User', ['editUser']),
    ...mapMutations('Account', ['setIsEdit']),
    ...mapMutations('Base', ['setBreadCrumbs']),
    handleLimit () {
      this.$message.error('Upload maksimal 1 gambar!')
    },
    handleShowEdit (isEdited) {
      if (!isEdited) {
        return
      }
      this.$refs.refShowEditImage.click()
      this.setImageTarget('avatar')
    },
    async handleChange (file) {
      this.showUpload = false
      this.openCropper()
      const urlImage = await this.toBase64(file.raw)
      this.dataCropper = {
        original: urlImage,
        name: file.name,
        url: urlImage,
        cropped: ''
      }
    },
    handleError (err) {
      this.showUpload = false
      this.$refs.uploadFiles.uploadFiles.pop()
      const errorMessage = err ? err.message : err
      this.$Swal.fire({
        icon: 'error',
        title:
            'Gagal upload foto ' +
            (this.target === 'cover_image' ? 'sampul' : 'profil'),
        text: errorMessage
      })
    },
    async handleSuccess (response) {
      if (response.status_code === 201) {
        this.showUpload = false
        const payload = {
          data: {
            medias: [
              {
                name: this.target,
                type: 'image',
                value: response.data.files[0].filename
              }
            ]
          }
        }
        try {
          const resp = await this.editUser(payload)
          if (resp.status === 200) {
            this.$Swal
              .fire({
                icon: 'success',
                title:
                    'Berhasil mengubah foto ' +
                    (this.target === 'cover_image' ? 'sampul' : 'profil')
              })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location = '/account/profile'
                }
              })
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    beforeUpload (file) {
      const isImage = file.type.substr(0, 5) === 'image'
      const isLimit5M = file.size / 1024 / 1024 < 6
      if (!isImage) {
        this.$message.error('File tidak sesuai, harap masukan .png atau .jpg!')
        this.$refs.uploadFiles.uploadFiles =
            this.$refs.uploadFiles.uploadFiles.pop()
      }
      if (!isLimit5M) {
        this.$message.error('Ukuran file tidak boleh lebih dari 5Mb!')
        this.$refs.uploadFiles.uploadFiles =
            this.$refs.uploadFiles.uploadFiles.pop()
      }
      return isImage && isLimit5M
    },
    handlePictureCardPreview () {
      this.dialogImageUrl = this.formUser.cropped
      this.dialogVisible = true
    },
    setImageTarget (target) {
      if (target === 'cover_image') {
        this.imgSrc = this.haveCover.length
          ? this.haveCover[0].value
          : '/img/account/profile/bg.png'
      } else {
        this.imgSrc = this.user.files.length
          ? this.getImageOriginal(this.user.files, 'avatar')
          : this.getDefaultAvatar(
            this.user.detail ? this.user.detail.gender : 'male'
          )
      }
      this.target = target
    },
    async handleSubmitImage () {
      this.showUpload = true
      // this.$refs.uploadFiles.submit()
      const _file = this.dataURLtoFile(
        this.formUser.cropped,
        this.formUser.name
      )
      const formData = new FormData()
      formData.append('file[]', _file)
      const response = await this.uploadFiles(formData)
      if (response.status === 201) {
        const payload = {
          data: {
            medias: [
              {
                name: this.target,
                type: 'image',
                value: response.data.data.files[0].filename
              }
            ]
          }
        }
        try {
          const resp = await this.editUser(payload)
          if (resp.status === 200) {
            this.$Swal
              .fire({
                icon: 'success',
                title:
                    'Berhasil mengubah foto ' +
                    (this.target === 'cover_image' ? 'sampul' : 'profil')
              })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location = '/account/profile'
                }
              })
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    openCropper () {
      this.$refs.showModalCrop.click()
    },
    handleCropped (value) {
      this.formUser = value
      this.dataCropper = {}
    },
    handleEdit () {
      console.log('masuk edit')
    },
    handleCloseUpload () {
      this.formUser = {
        url: '',
        original: '',
        name: '',
        cropped: ''
      }
    }
  }
}
