//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { affiliateCommissionBalanceTypes } from '@/utils/helpers/affiliate-commission-helper'

export default {
  props: {
    commissionHistoryDetail: {
      type: Object,
      default: () => ({
        loading: true,
        data: null
      })
    }
  },

  data () {
    return {
      active: ['1']
    }
  },

  computed: {
    isDetailCommissionHistoryTypeIn () {
      return this.commissionHistoryDetail.balanceType === affiliateCommissionBalanceTypes.in.value
    },

    isDetailCommissionHistoryTypeOut () {
      return this.commissionHistoryDetail.balanceType === affiliateCommissionBalanceTypes.out.value
    }
  }
}
