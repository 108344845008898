//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'

export default {
  mixins: [panstoreMixin],

  data () {
    return {
      flashSaleProfile: {
        isLoading: true,
        data: []
      },

      loading: true,
      checkDataFlashsales: false,
      locDataFlashsales: []
    }
  },

  computed: {
    ...mapState('Flashsale', ['flashsale', 'dataFlashsales', 'isLoading']),
    ...mapState('Partner', ['partner']),

    countDownFlashsale () {
      if (this.getFlashTime(this.flashsale[0].date_start)) {
        return this.countdownTime(this.flashsale[0].date_end)
      } else {
        return this.countdownTime(this.flashsale[0].date_start)
      }
    }
  },

  watch: {
    isLoading () {
      if (!this.isLoading) {
        this.locDataFlashsales = this.dataFlashsales
      }
    }
  },

  mounted () {
    this.getFlashsalePartner()
  },

  methods: {
    ...mapActions('Flashsale', ['getOngoingSession']),

    async getFlashsalePartner () {
      this.flashSaleProfile.isLoading = true

      const date = new Date()
      const today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()

      try {
        const respFlashsale = await this.getOngoingSession({
          data: {
            partner_id: [this.partner.id],

            where_or_greater_than: {
              date_start: today + ' 00:00:00',
              date_end: today + ' 00:00:00'
            },
            where_or_lower_than: {
              date_start: today + ' 23:59:59',
              date_end: today + ' 23:59:59'
            },
            where_has: [
              {
                relation: 'product_details.product.store',
                where: {
                  'stores.id': this.partner.id
                }
              },
              {
                relation: 'event_sale',
                where: {
                  type: 'flash_sale'
                }
              }
            ]
          }
        })

        this.flashSaleProfile.data = respFlashsale.data.data.event_sale_sessions
      } catch (error) {
        this.$message.error('Gagal mendapatkan data section flash sale partner')
      }

      this.flashSaleProfile.isLoading = false
    },

    getFlashSaleStartedAtText () {
      let flashSaleStartedAtText = ''

      if (this.flashsale.length > 0) {
        flashSaleStartedAtText = !this.getFlashTime(this.flashsale[0].date_start)
          ? 'Dimulai Pada ' + this.getFSHours(this.flashsale[0].date_start)
          : 'Sedang Berlangsung'
      }

      return flashSaleStartedAtText
    },

    countdownEnd () {
      window.location.reload()
    },

    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },

    getFlashTime (start) {
      const tempStart =
        start.substr(3, 2) +
        '-' +
        start.substr(0, 2) +
        '-' +
        start.substr(6, 4) +
        start.substr(10, 9);
      const startTime = this.$dayjs(tempStart);
      const dateNow = this.$dayjs(this.convertTZ(new Date()));

      return dateNow.isAfter(startTime);
    }
  }
};
