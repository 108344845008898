//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { defaultPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { affiliateBalanceWithdrawalStatuses } from '@/utils/helpers/affiliate-balance-withdrawal-helper'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    commissionHistoriesFilter: {
      type: Object,
      default: () => ({})
    },

    commissionHistoriesData: {
      type: Object,
      default: () => {
        return {
          ...defaultPaginationAttributes()
        }
      }
    }
  },

  data () {
    return {
      infiniteId: generateRandomString(4)
    }
  },

  computed: {

    filterStatuses () {
      return Object.entries(affiliateBalanceWithdrawalStatuses).map(([_key, value]) => {
        return {
          label: value.label,
          value: value.value
        }
      })
    },

    commissionHistories () {
      const listHistoriesByMonth = [];

      this.commissionHistoriesData.data.forEach((dt) => {
        if (!dt.created_at) { return }

        const titleDate = formatDateWithTimezone(dt.created_at, { formatResult: 'MM-YYYY' })

        // Find or create a month entry in tempMonths
        let monthEntry = listHistoriesByMonth.find(tmp => tmp.titleDate === titleDate)

        if (!monthEntry) {
          monthEntry = {
            titleDate,
            dateRaw: dt.created_at,
            data: []
          }
          listHistoriesByMonth.push(monthEntry)
        }

        monthEntry.data.push(dt)
      })

      return listHistoriesByMonth
    }
  },

  methods: {
    generateRandomString,
    formatDateWithTimezone,

    handleClickFilterBalanceType (item) {
      this.$emit('filter-balance-type-change', item)
    },

    handleClickFilterStatus (item) {
      this.$emit('filter-status-change', item)
    },

    handleInfiniteLoadingCommissionHistory ($state) {
      this.$emit('infinite-loading', $state)
    },

    completeInfiniteLoading () {
      this.$refs.InfiniteLoadingCommissionHistory.stateChanger.complete()
    },

    resetInfiniteLoading () {
      this.$refs.InfiniteLoadingCommissionHistory.stateChanger.reset()
    },

    handleCommissionHistoryItemClick (commissionHistoryData) {
      this.$emit('commission-history-item-click', commissionHistoryData)
    }
  }
}
