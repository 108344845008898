import { render, staticRenderFns } from "./ProductImagesFooter.vue?vue&type=template&id=436d5644&scoped=true&"
import script from "./ProductImagesFooter.vue?vue&type=script&lang=js&"
export * from "./ProductImagesFooter.vue?vue&type=script&lang=js&"
import style0 from "./ProductImagesFooter.vue?vue&type=style&index=0&id=436d5644&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436d5644",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconShareAndroid: require('/usr/src/app/components/icons/IconShareAndroid.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,IconHeartLikeWishlistFilled: require('/usr/src/app/components/icons/IconHeartLikeWishlistFilled.vue').default,IconHeartLikeWishlistEmpty: require('/usr/src/app/components/icons/IconHeartLikeWishlistEmpty.vue').default,IconPlus: require('/usr/src/app/components/icons/IconPlus.vue').default,DividerVertical: require('/usr/src/app/components/base/DividerVertical.vue').default})
