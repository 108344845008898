//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

import {
  assignResponseToDataModelData,
  defaultPaginationAttributes,
  resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { waitFor } from '@/services/axiosConfig'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { affiliateBalanceWithdrawalStatuses } from '@/utils/helpers/affiliate-balance-withdrawal-helper'
import { homePagePaths } from '~/utils/helpers/page-helper'

export default {
  layout: 'account',
  middleware: ['user', 'affiliate'],

  data () {
    return {
      breadCrumb: [
        {
          path: homePagePaths.account
        },
        {
          name: 'Afiliasi',
          path: '/account/affiliate/'
        },
        {
          name: 'Komisi',
          path: '/account/affiliate/commission'
        },
        {
          name: 'Riwayat Pengajuan Penarikan Komisi'
        }
      ],

      commissionWithdrawalHistoryFilterPeriods: {
        all: {
          label: 'Sepanjang Waktu',
          value: 'all',
          infoText: ''
        },
        today: {
          label: 'Hari ini',
          value: 'today',
          infoText: 'Dari kemarin'
        },
        'last-week': {
          label: 'Minggu ini',
          value: 'last-week',
          infoText: 'Dari minggu lalu'
        },
        '30-days': {
          label: 'Bulan ini',
          value: '30-days',
          infoText: 'Dari bulan lalu'
        }
      },

      commissionWithdrawalHistoryFilter: {
        periodDate: 'all',
        status: 'all'
      },

      getCommissionHistoriesData: {
        ...defaultPaginationAttributes()
      },

      commissionHistoryDetail: {
        loading: false,
        data: null
      }
    }
  },

  methods: {
    ...mapActions('Affiliate', ['getAffiliateBalanceWithdrawal', 'getAffiliateBalanceWithdrawalDetail']),

    generateRandomString,

    handleCommissionWithdrawalHistoryFilterChange () {
      this.$refs.listCommissionWithdrawalSubmissionHistoriesByMonth.completeInfiniteLoading()

      resetPaginationData(this.getCommissionHistoriesData)

      this.$refs.listCommissionWithdrawalSubmissionHistoriesByMonth.resetInfiniteLoading()
    },

    handleFilterStatusChange (status) {
      if (this.commissionWithdrawalHistoryFilter.status === status.value) {
        return
      }

      this.$refs.listCommissionWithdrawalSubmissionHistoriesByMonth.completeInfiniteLoading()

      resetPaginationData(this.getCommissionHistoriesData)
      this.commissionWithdrawalHistoryFilter.status = status.value

      this.$refs.listCommissionWithdrawalSubmissionHistoriesByMonth.resetInfiniteLoading()
    },

    async handleInfiniteLoadingCommissionHistories ($state) {
      if (this.getCommissionHistoriesData.page >= this.getCommissionHistoriesData.totalPage) {
        $state.complete()
        return
      }

      await this.getAffiliateCommissionHistories()
      $state.loaded()
    },

    async getAffiliateCommissionHistories () {
      try {
        this.getCommissionHistoriesData.loading = true

        const payload = {
          params: {
            paginated: this.getCommissionHistoriesData.paginated,
            per_page: this.getCommissionHistoriesData.perPage,
            page: this.getCommissionHistoriesData.page + 1
          }
        }

        if (this.commissionWithdrawalHistoryFilter.periodDate !== this.commissionWithdrawalHistoryFilterPeriods.all.value) {
          payload.params.period_date = this.commissionWithdrawalHistoryFilter.periodDate
        }

        if (this.commissionWithdrawalHistoryFilter.status !== affiliateBalanceWithdrawalStatuses.all.value) {
          payload.params.status = this.commissionWithdrawalHistoryFilter.status
        }

        const response = await this.getAffiliateBalanceWithdrawal(payload)

        assignResponseToDataModelData(
          this.getCommissionHistoriesData,
          response.data.data.affiliate_balance_withdrawals,
          false
        )
      } catch (error) {
        console.log(error)
        this.$message.error(getResponseErrorMessage(error))
        this.$refs.listCommissionWithdrawalSubmissionHistoriesByMonth.completeInfiniteLoading()
      } finally {
        this.getCommissionHistoriesData.loading = false
      }
    },

    async handleCommissionHistoryItemClick (commissionHistoryItem) {
      try {
        this.commissionHistoryDetail.data = null
        this.$refs.btnOpenCommissionWithdrawalSubmissionHistoryDetail.click()
        this.commissionHistoryDetail.loading = true

        const response = await this.getAffiliateBalanceWithdrawalDetail({
          id: commissionHistoryItem.id
        })
        this.commissionHistoryDetail.data = response.data.data.affiliate_balance_withdrawal

        await waitFor(700)
      } catch (error) {
        this.$message.error(getResponseErrorMessage(error))
      } finally {
        this.commissionHistoryDetail.loading = false
      }
    }
  }
}
