//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { storeTypes } from '@/utils/helpers/store-helper'

export default {
  mixins: [mixins],
  props: {
    store: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    storeTypes () {
      return storeTypes
    }
  },
  methods: {
    handleGoto (id) {
      this.$router.push('/partner/' + id)
    }
  }
}
