//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('Cart', ['cart', 'isLoading', 'carts'])
  },
  mounted () {
    setTimeout(() => (this.setLoading(false)), 2000)
  },
  methods: {
    ...mapMutations('Cart', ['setLoading'])
  }
}
