//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import {
  affiliateCommissionStatFooterTypes,
  affiliateCommissionStatTrendStatuses
} from '@/utils/helpers/affiliate-commission-helper'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    fixedFontSize: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    affiliateCommissionStatFooterTypes () {
      return affiliateCommissionStatFooterTypes
    },

    affiliateCommissionStatTrendStatuses () {
      return affiliateCommissionStatTrendStatuses
    },

    indicatorImage () {
      let image = null
      switch (this.data.trendStatus) {
        case affiliateCommissionStatTrendStatuses.constant.value:
          image = '/img/icon/icon-constant-blue.svg'
          break
        case affiliateCommissionStatTrendStatuses.up.value:
          image = '/img/icon/icon-up-green.svg'
          break
        case affiliateCommissionStatTrendStatuses.down.value:
          image = '/img/icon/icon-down-red.svg'
          break
      }

      return image
    },

    cardClass () {
      let classes = 'general'

      switch (this.data.trendStatus) {
        case affiliateCommissionStatTrendStatuses.constant.value:
          classes = 'constant'
          break
        case affiliateCommissionStatTrendStatuses.up.value:
          classes = 'green'
          break
        case affiliateCommissionStatTrendStatuses.down.value:
          classes = 'red'
          break
      }

      return classes
    }
  },

  methods: { formatRupiah }
}
