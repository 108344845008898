import { render, staticRenderFns } from "./CartCardProduct.vue?vue&type=template&id=08130e8b&scoped=true&"
import script from "./CartCardProduct.vue?vue&type=script&lang=js&"
export * from "./CartCardProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08130e8b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Badge: require('/usr/src/app/components/base/Badge.vue').default,IconHeartFill: require('/usr/src/app/components/icons/IconHeartFill.vue').default,IconHeartOutline: require('/usr/src/app/components/icons/IconHeartOutline.vue').default,IconTrash: require('/usr/src/app/components/icons/IconTrash.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default})
