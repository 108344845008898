//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
import _ from 'lodash'

import { getResponseErrorMessage } from '@/utils/helpers/response-helper'

export default {
  mixins: [panstoreMixin],
  data () {
    return {
      // processedCarts: [],
      minCountItem: 1,
      maxCountItem: 2,
      alertStore: false,
      deleteAll: false,
      onIncrease: false,
      selectedItemCheck: [],
      maxQuantity: false,
      productMaxQuantity: [],
      mappingStore: false,
      auth: [],
      currentValue: 0,
      handleLoading: false
    }
  },
  computed: {
    ...mapState('Cart', ['carts', 'cart', 'selectItem', 'store', 'isLoading']),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapState('Checkout', ['usePoint']),
    ...mapGetters('Cart', ['getCheckDisabledGotoCheckout']),
    processedCarts () {
      return this.handleProcessedCarts()
    },
    disabledInfinite () {
      return this.handleLoading || !this.carts.pagination.nextPage
    }
  },
  mounted () {
    this.auth = this.$auth
    if (
      localStorage.cartItem ||
        localStorage.cartPrice ||
        localStorage.cartBranchId
    ) {
      localStorage.removeItem('cartItem')
      localStorage.removeItem('cartPrice')
      localStorage.removeItem('cartBranchId')
      this.setSelectedStore('')
      this.resetItem([])
    }
  },
  methods: {
    ...mapActions('Cart', ['getCarts', 'getCartsNavbar', 'updateCart', 'selectedItem', 'deleteCart', 'getCartSummary']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapMutations('Cart', [
      'setCartItemId',
      'setCartItemQuantity',
      'setDeleteItem',
      'setSelectedItem',
      'setSelectedCartGroups',
      'resetSelectedItem',
      'setCheckoutItem',
      'resetItem',
      'setSelectedStore',
      'setWishlistCartProduct',
      'resetCarts',
      'setRemoveDeletedCartProduct',
      'setSourceDelete'
    ]),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    ...mapActions('Product', ['getProductWishlist']),
    handleProcessedCarts () {
        const resultProcessed = []

        if (!this.carts.data.length) {
          return
        }

        const localCarts = JSON.parse(JSON.stringify(this.carts))

        localCarts.data.forEach((cart) => {
          if (this.checkObject(cart) && this.checkObject(cart.cart_group) && this.checkObjectKey(cart.cart_group, 'is_fulfilled')) {
            const cartObj = {
              isFulfilled: cart.cart_group.is_fulfilled,
              cart_group_id: cart.cart_group.id,
              stores: [],
              checkAll: false
            }

            // cart group is fulfilled
            if (cart.cart_group.is_fulfilled) {
              // check existing store
              const storeExistIdx = resultProcessed.findIndex((exCart) => {
                return exCart.stores.some((exStore) => {
                  return exStore.id === cart.cart_group.store.id
                })
              })

              // handle store cart exist
              if (storeExistIdx > -1) {
                const exitstStoreObj = resultProcessed[storeExistIdx]

                delete cart.cart_group
                exitstStoreObj.stores[0].cart_items.push({
                  ...cart,
                  check: false
                })

                // update existing store cart item
                resultProcessed.splice(storeExistIdx, 1, exitstStoreObj)
              } else { // handle new store cart
                cartObj.stores = [{
                  cart_group_id: cart.cart_group.id,
                  ...cart.cart_group.store,
                  cart_items: [
                    {
                      ...cart,
                      check: false
                    }
                  ]
                }]

                // remove cart_groups fron processed obj
                delete cartObj.stores[0].cart_items[0].cart_group

                // push cartObj
                resultProcessed.push(cartObj)
              }
            } else { // cart is not fulfilled
              // check existing store
              const storeExistIdx = resultProcessed.findIndex((exCart) => {
                return exCart.stores.some((exStore) => {
                  return exStore.id === cart.cart_group.store.id
                })
              })

              // handle store cart exist
              if (storeExistIdx > -1) {
                const exitstStoreObj = resultProcessed[storeExistIdx]

                delete cart.cart_group
                exitstStoreObj.stores[0].cart_items.push({
                  ...cart,
                  check: false
                })

                // update existing store cart item
                resultProcessed.splice(storeExistIdx, 1, exitstStoreObj)
              } else { // hanle new store cart
                cartObj.stores = [{
                  cart_group_id: cart.cart_group.id,
                  ...cart.cart_group.store,
                  cart_items: [
                    {
                      ...cart,
                      check: false
                    }
                  ]
                }]

                // remove cart_groups fron processed obj
                delete cartObj.stores[0].cart_items[0].cart_group

                // push cartObj
                resultProcessed.push(cartObj)
              }
            }
          }
        })
        // this.processedCarts = resultProcessed
        return resultProcessed
    },
    async handleInfiniteLoading ($state) {
      await this.fetchAvailableCarts($state)
    },
    async fetchAvailableCarts ($stateInfinite = null, paginated = true) {
      if (!this.carts.pagination.nextPage && paginated) {
        $stateInfinite.complete()
        return
      }
      this.handleLoading = true
      try {
        const payloadAvailable = {
          params: {
            paginated,
            page: this.carts.pagination.nextPage || 1,
            per_page: 10,
            session_id: this.$cookies.get('session_id')
          }
        }

        if ($stateInfinite) {
          payloadAvailable.params.onLoadMore = !!$stateInfinite
        }

        const resp = await this.getCarts(payloadAvailable)
        if (paginated ? resp.data.data.carts.data.length : resp.data.data.carts) {
          if ($stateInfinite) {
            $stateInfinite.loaded()
          }
        } else if ($stateInfinite) {
            $stateInfinite.complete()
          }
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        this.flashAlert(msg)
      } finally {
        this.handleLoading = false
      }
    },
    async fetchAvailableSummaryCarts () {
      try {
        await this.getCartSummary({
              params: {
                unavailable_stock: false,
                session_id: this.$cookies.get('session_id')
              }
            })
      } catch (error) {
        console.log(error)
      }
    },
    async handleToggleWishlist (cartId, id, isWishlisted) {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_id: [id]
            }
          })
          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })
            this.setWishlistCartProduct({
              ids: [id],
              condition: isWishlisted,
              source: 'carts'
            })
            this.$message({
              message: `Produk berhasil ${
                  isWishlisted ? 'dihapus dari ' : 'ditambahkan ke'
                } Wishlist.`,
              type: 'success',
              showClose: true
            })
          }
        } catch (error) {
          const msg = getResponseErrorMessage(error)
          this.flashAlert(msg)
        }
      } else {
        this.$alert('Silahkan login terlebih dahulu!')
      }
    },
    async handleUpdateCartProduct (idProduct, newQuantity, type) {
      this.onIncrease = true
      const payload = {
        id: idProduct,
        data: {
          quantity: newQuantity,
          session_id: this.$cookies.get('session_id')
        }
      }

      if (type) {
        payload.data.action = type
      }

      try {
        await this.updateCart(payload)
        this.setCartItemQuantity({ id: idProduct, currentVal: newQuantity, type })

        if (type === 'increment' || type === 'decrement') {
          this.$message({
            showClose: true,
            message: `Berhasil ${
                type === 'increment' ? 'menambahkan' : 'mengurangi'
              } item ${type === 'increment' ? 'ke' : 'di'} Keranjang`,
            type: 'success'
          })
        } else {
          this.$message({
            showClose: true,
            message: 'Berhasil merubah item di keranjang',
            type: 'success'
          })
        }

        this.setCartItemQuantity({ id: idProduct, currentVal: newQuantity, type })
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        this.flashAlert(msg)
      }
      try {
        const payloadCartNavbar = {
          params: {
            session_id: this.$cookies.get('session_id')
          }
        }
        await this.getCartsNavbar(payloadCartNavbar)
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        this.flashAlert(msg)
      } finally {
        this.onIncrease = false
      }
    },
    debounceUpdateCart: _.debounce(async function (id, currentVal, type) {
      await this.handleUpdateCartProduct(id, currentVal, type)
      await this.handleCheckVoucher()
    }, 700),
    increaseCounter (currentVal, id, increaseLimit) {
      // Increase
      if (currentVal < increaseLimit) {
        currentVal++
        this.maxQuantity = false
        this.setMaxQuantity(id, 'delete')
      } else {
        this.maxQuantity = true
        this.setMaxQuantity(id, 'add')
      }
      this.debounceUpdateCart(id, currentVal, 'increment')
    },
    decreaseCounter (currentVal, id, decreaseLimit) {
      // Decrease
      if (currentVal > decreaseLimit) {
        currentVal--
        this.maxQuantity = false
        this.setMaxQuantity(id, 'delete')
      }
      this.debounceUpdateCart(id, currentVal, 'decrement')
    },
    setMaxQuantity (id, action = 'add') {
      if (action === 'add') {
        const checkExist = this.productMaxQuantity.includes(id)

        if (!checkExist) {
          this.productMaxQuantity.push(id)
        }
      } else if (action === 'delete') {
        const indexProduct = this.productMaxQuantity.indexOf(id)

        if (indexProduct > -1) {
          this.productMaxQuantity.splice(indexProduct, 1)
        }
      }
    },
    async handleCheckVoucher () {
      const oldVoucher = this.voucherUsed
      if (
        this.selectItem.length &&
          Object.keys(oldVoucher).length &&
          !this.mappingStore
      ) {
        const voucherId = oldVoucher.id
        const response = await this.checkAvailibilities(
          this.selectItem,
          null,
          null,
          null,
          null,
          false,
          voucherId,
          this.usePoint
        )
        const newVoucher = response.data.data.checkout.voucher
        if (newVoucher) {
          const payload = {
            code: newVoucher.code || null,
            reduction_type: newVoucher.reduction_type,
            reduction_value: newVoucher.reduction_value,
            total_voucher_discount: newVoucher.total_voucher_discount,
            type: newVoucher.type
          }
          const finalVoucher = Object.assign(
            JSON.parse(JSON.stringify(oldVoucher)),
            payload
          )
          this.setVoucherUsed(finalVoucher)
        }
      }
    },
    flashAlert (msg = '') {
      this.$message({
        showClose: true,
        message: msg || 'Hanya bisa pesan dari 1 toko, dalam 1 kali Checkout ',
        type: 'warning'
      })
    },
    async handleCheckAll (e) {
      if (e.target.checked) {
        this.resetCarts()
        await this.fetchAvailableCarts(null, false)
        await this.fetchAvailableSummaryCarts()
      } else {
        this.resetSelectedItem()
      }
      setTimeout(() => {
        this.processedCarts.forEach((cart) => {
          this.checkAllGroup(cart, e.target.checked)
        })
      }, 100)
    },
    checkItem (id, storeId, cartGroupId, isFulfilled, cartItem) {
      const dataCart = this.selectItem

      if (this.$refs[`checkProduct-${id}`][0].checked) {
        // this.setSelectedStore(storeId)
        const payload = {
          cart_id: id
        }
        this.setCheckoutItem(payload.cart_id)
        this.setSelectedItem({
          cartId: id,
          isFlashsale: cartItem.is_flash_sale,
          isPresale: cartItem.is_pre_sale,
          isPreorder: cartItem.is_preorder,
          preorderPeriod: cartItem.preorder_period,
          isFulfilled,
          storeId,
          cartGroupId
        })
      } else if (dataCart.some(dt => dt.cartId === id)) {
        // this.setSelectedStore('')
        const payload = {
          cart_id: id
        }
        this.setCheckoutItem(payload.cart_id)
        this.setSelectedItem({
          cartId: id,
          isFlashsale: cartItem.is_flash_sale,
          isPresale: cartItem.is_pre_sale,
          isPreorder: cartItem.is_preorder,
          preorderPeriod: cartItem.preorder_period,
          isFulfilled,
          storeId,
          cartGroupId
        })
      }

      this.processedCarts.forEach((c) => {
        const indexStore = c.stores.findIndex((store) => {
          return store.id === storeId
        })

        if (indexStore > -1) {
          const filteredSelectItem = this.selectItem.filter((select) => {
            return (select.cartGroupId === cartGroupId) && (select.storeId === storeId)
          })

          if (filteredSelectItem.length === c.stores[indexStore].cart_items.length) {
            this.deleteAll = true
            this.$refs[`cartGroup-${cartGroupId}`][0].indeterminate = false
            this.$refs[`cartGroup-${cartGroupId}`][0].checked = true
          } else if (filteredSelectItem.length > 0 && filteredSelectItem.length < c.stores[indexStore].cart_items.length) {
            this.deleteAll = false
            this.$refs[`cartGroup-${cartGroupId}`][0].indeterminate = true
          } else {
            this.deleteAll = false
            this.$refs[`cartGroup-${cartGroupId}`][0].indeterminate = false
            this.$refs[`cartGroup-${cartGroupId}`][0].checked = false
          }
        }
      })

      if (!this.selectItem.length) {
        this.$refs.checkAll.checked = false
        this.$refs.checkAll.indeterminate = false
      } else if (this.selectItem.length > 0 && this.selectItem.length < this.carts.pagination.total) {
        this.$refs.checkAll.indeterminate = true
      } else if (this.selectItem.length === this.carts.pagination.total) {
        this.$refs.checkAll.checked = true
        this.$refs.checkAll.indeterminate = false
      }
      this.handleCheckVoucher()
    },
    checkAllGroup (data, isCheckAll) {
      this.mappingStore = true
      if (!this.checkArray(this.$refs[`cartGroup-${data.cart_group_id}`])) {
        return
      }
      let checked = this.$refs[`cartGroup-${data.cart_group_id}`][0].checked

      if (typeof isCheckAll === 'boolean') {
        this.$refs[`cartGroup-${data.cart_group_id}`][0].checked = isCheckAll
        checked = isCheckAll
        this.$refs[`cartGroup-${data.cart_group_id}`][0].indeterminate = false
      }

      this.setSelectedStore(data.id)
      data.stores.forEach((store) => {
        store.cart_items.forEach((item, i) => {
          if (checked !== this.$refs[`checkProduct-${item.id}`][0].checked) {
            if (i === store.cart_items.length - 1) {
              this.mappingStore = false
            }
            this.$refs[`checkProduct-${item.id}`][0].click()
          } else if (i === store.cart_items.length - 1) {
            this.mappingStore = false
          }
        })
      })
      this.deleteAll = checked
      this.alertStore = false
    },
    getChecked (id) {
      this.selectItem.map((item) => {
        if (item === id) {
          return true
        }
      })
      return false
    },
    deleteModal (item, id) {
      this.setCartItemId(id)
      this.setDeleteItem(item)
      this.setSourceDelete('available')
    },
    handleDeleteAll () {
      this.$Swal
        .fire({
          title: 'Apakah Anda Yakin?',
          text: `Anda akan menghapus ${this.selectItem.length} produk di keranjang`,
          icon: 'question',
          showCloseButton: true,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ya, Hapus',
          cancelButtonText: 'Batal',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'paninti-btn paninti-btn-primary',
            cancelButton: 'paninti-btn paninti-btn-dark-outline paninti-mr-8'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.handleDeleteCart()
          }
        })
    },
    async handleDeleteCart () {
      const deletedCartsId = this.selectItem.map((item) => {
            return item.cartId
          })
      const countCarts = deletedCartsId.length
      const payload = {
        data: {
          id: deletedCartsId,
          session_id: this.$cookies.get('session_id')
        }
      }
      try {
        const resp = await this.deleteCart(payload)
        if (resp.data.status_code === 200) {
          this.$message({
            message: `${countCarts} produk berhasil dihapus dari keranjang.`,
            type: 'success',
            showClose: true
          })

          this.setRemoveDeletedCartProduct({
            ids: deletedCartsId,
            all: false
          })
          this.resetSelectedItem()
          setTimeout(() => {
            this.processedCarts.forEach((cart) => {
              this.checkAllGroup(cart, false)
            })
            this.$refs.checkAll.indeterminate = false
            this.$refs.checkAll.checked = false
          }, 100)
        }
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        this.flashAlert(msg)
      }
    },
    inputQty (e, id, stock) {
      let qty = Number(e.target.value)
      if (qty <= 0) {
        qty = 1
      }
      if (qty > stock) {
        qty = stock
      }
      this.handleUpdateCartProduct(id, qty)
    }
  }
}
