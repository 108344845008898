import { render, staticRenderFns } from "./index.vue?vue&type=template&id=697d7a8d&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,ProfileBanner: require('/usr/src/app/components/account/profile/ProfileBanner.vue').default,FormEditProfile: require('/usr/src/app/components/account/profile/FormEditProfile.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
