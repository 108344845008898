//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins';

export default {
  mixins: [mixins],

  props: {
    partner: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    onCardPartnerRelatedActionClicked (slug) {
      this.$router.push(`/partner/${slug}`)
    }
  }
}
