//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      price: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState('Product', ['product', 'detailProduct']),
    ...mapState('User/address', ['location', 'userAddress', 'isCreated']),
    ...mapState('Cart', ['qty', 'idCart']),
    isFlashsale () {
      if (Object.keys(this.detailProduct).length) {
        if (this.detailProduct.event_sale_session) {
          return this.detailProduct.event_sale_session
            .event_sale_session_product_detail_id
        }
      }
      return false
    },
    isEventsale () {
      if (Object.keys(this.detailProduct).length) {
        if (this.detailProduct.event_ongoing.event_sale) {
          return this.detailProduct.event_ongoing.event_sale
        }
      }
      return false
    },
    user () {
      return this.$auth.user
    }
  },
  watch: {
    detailProduct: {
      handler () {
        if (!this.loading && Object.keys(this.detailProduct).length) {
          setTimeout(async () => {
            this.isLoading = true
            await this.getData(this.detailProduct.id)
          }, 500)
        }
      },
      deep: true
    },
    location () {
      if (!this.loading && Object.keys(this.detailProduct).length) {
        this.isLoading = true
        this.getData(this.detailProduct.id)
      }

      // if (
      //   this.product.type === 'combination' &&
      //   !Object.keys(this.detailProduct).length
      // ) {
      //   this.getData(this.mainVariant)
      // }
    },
    qty () {
      if (!this.loading) {
        this.isLoading = true
        this.getData(
          Object.keys(this.detailProduct).length
            ? this.detailProduct.id
            : this.product.id
        )
      }
    },
    isCreated () {
      this.addToCart(true)
    }
  },
  methods: {
    ...mapActions('Product', ['getEstimatePrice']),
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar']),
    ...mapMutations('Cart', [
      'setSelectedProduct',
      'setSelectedVariant',
      'setQty'
    ]),
    ...mapMutations('Base', ['SET_ERROR']),
    async getData (id) {
      this.isLoading = true
      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155
        const resp = await this.getEstimatePrice({
          data: {
            product_detail_id: id,
            city_id: cityId,
            quantity: this.qty
          }
        })

        this.price = resp.data.data.product.price_with_shipping_cost
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async addToCart (direct) {
      this.isLoading = true
      const productId = this.detailProduct.id
      const payload = Object.assign({
        data: {
          product_detail_id: productId,
          store_id: this.product.store.id,
          is_fulfilled: this.product.is_fulfilled,
          quantity: this.qty,
          session_id: this.$cookies.get('session_id')
        }
      })

      if (this.$route.query.affiliate_code) {
        payload.data.affiliate_code = this.$route.query.affiliate_code
      }

      if (Object.keys(this.detailProduct).length) {
        if (!direct) {
          await this.setSelectedProduct(this.product)
        } else {
          payload.data = Object.assign({
            ...payload.data,
            cartable_id: productId,
            cartable_type: 'product_details'
          })
        }

        try {
          const resp = await this.addCart(payload)
          localStorage.removeItem('cartItem')
          if (resp.data.status_code === 201) {
            if (!direct) {
              await this.setSelectedVariant(productId)
              await this.handleGetCart()
              this.setQty(1)
              this.$refs.showAlert.click()
            } else {
              const cartItem = []
              cartItem.push(resp.data.data.cart.id)
              localStorage.cartItem = JSON.stringify(cartItem)
              if (this.$auth.loggedIn) {
                this.$router.push('/checkout')
              } else {
                this.$router.push('/login')
              }
            }
          }
        } catch (error) {
          console.log(error)
          this.$message.error(error.response.data.message)
        } finally {
          this.isLoading = false
        }
      } else {
        this.SET_ERROR(true)
        window.scroll(0, 0)
        this.$message.error('Pilih variasi terlebih dahulu!')
      }
      this.isLoading = false
    },
    handleBuyNow () {
      if (Object.keys(this.detailProduct).length) {
        if (this.$auth.loggedIn) {
          if (
            this.$auth.user.main_address ||
            this.userAddress.length ||
            this.detailProduct.event_ongoing.event_sale.length
          ) {
            this.addToCart(true)
          } else {
            this.$Swal
              .fire({
                title: 'Anda belum memiliki alamat',
                text:
                  'Silahkan tambah alamat untuk melanjutkan ke proses checkout',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Tambah alamat'
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$refs.buttonCreateAddress.click()
                }
              })
          }
        } else {
          const productUrl = this.$route.path
          this.$router.push(`/login?redirect_from=${productUrl}`)
        }
      } else {
        this.SET_ERROR(true)
        window.scroll(0, 0)
        this.$message.error('Pilih variasi terlebih dahulu!')
      }
    },
    async handleGetCart () {
      const payload = {
        session_id: this.$cookies.get('session_id')
      }
      try {
        await this.getCartsNavbar({ data: payload })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
