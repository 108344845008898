//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    return {
      onSubmit: false,
      isVerif: false,
      formData: {
        current_password: '',
        new_password: '',
        confirm_new_password: ''
      },
      errorCurrentPassword: null
    }
  },
  methods: {
    ...mapActions('User', ['changePassword', 'checkValidPassword']),
    handleSubmit () {
      this.onSubmit = true
      this.$refs.editPasswordForm.validate(async (valid) => {
        if (valid) {
          const payload = {
            data: {
              ...this.formData
            }
          }
          try {
            const resp = await this.changePassword(payload)
            if (resp.data.status_code === 200) {
              this.$Swal.fire({
                icon: 'success',
                title: 'Pemberitahuan',
                text: 'Kata sandi berhasil diperbaharui!',
                heightAuto: false
              })
              this.$router.push('/account/profile')
            }
          } catch (error) {
            this.$Swal.fire({
              icon: 'warning',
              title: 'Pemberitahuan',
              text: error.response.data.message,
              heightAuto: false
            })
          }
        }
      })
      this.onSubmit = false
    },
    handleVerif () {
      this.onSubmit = true
      this.$refs.editPasswordForm.validate(async (valid) => {
        if (valid) {
          const payload = {
            data: {
              ...this.formData
            }
          }
          try {
            const resp = await this.checkValidPassword(payload)
            if (resp.data.status_code === 200) {
              this.isVerif = true
            }
          } catch (error) {
            this.errorCurrentPassword = 'Kata sandi salah'
          }
        }
      })
      this.onSubmit = false
    },
    clearValidate () {
      if (this.errorCurrentPassword) {
        this.$refs.editPasswordForm.clearValidate()
        this.errorCurrentPassword = null
      }
    },
    validatorPassword (rule, value, callback) {
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

      if (value === ' ') {
        this.formData[rule.field] = ''
      }
      if (input === ' ') {
        this.formData[rule.field] = value.substr(0, value.length - 1)
      }
      if (
        rule.field === 'confirm_new_password' &&
        this.formData.new_password !== value
      ) {
        callback(new Error('Konfirmasi password tidak sesuai'))
      }
      if (value && !value.match(regex)) {
        callback(
          new Error('Password minimal berisi 1 huruf kapital dan 1 angka')
        )
      }
      callback()
    }
  }
}
