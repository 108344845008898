import { render, staticRenderFns } from "./_token.vue?vue&type=template&id=289aa10a&scoped=true&"
import script from "./_token.vue?vue&type=script&lang=js&"
export * from "./_token.vue?vue&type=script&lang=js&"
import style0 from "./_token.vue?vue&type=style&index=0&id=289aa10a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289aa10a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResetPasswordForm: require('/usr/src/app/components/auth/ResetPasswordForm.vue').default,AuthLayoutWrapper: require('/usr/src/app/components/auth/AuthLayoutWrapper.vue').default})
