//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins';

export default {
  mixins: [mixins],

  props: {
    article: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    onArticleCardClicked () {
      this.$gtag('event', 'click', {
        event_category: 'Article',
        event_label: 'Article Parenting Hub Homepage Clicked',
        value: this.article.title || ''
      })
    }
  }
}
