//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions, mapState } from 'vuex'
import { swal2Icons, defaultSwal2Options } from '@/utils/helpers/swal2-helper';
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper';
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
export default {
  props: {
    type: {
      type: String,
      default: 'add'
    }
  },
  data () {
    return {
      formData: this.initFormData(),
      rules: {
        title: [
          { required: true, message: 'Label Alamat harus diisi', trigger: 'blur' }
        ],
        recipient_name: [
          { required: true, message: 'Nama Penerima harus diisi', trigger: 'blur' }
        ],
        phone_number: [
          { required: true, message: 'No. Telepon harus diisi', trigger: 'blur' }
        ],
        province: [
          { required: true, message: 'Provinsi harus diisi', trigger: 'change' }
        ],
        city: [
          { required: true, message: 'Kota/Kabupaten harus diisi', trigger: 'change' }
        ],
        subdistrict: [
          { required: true, message: 'Kecamatan harus diisi', trigger: 'change' }
        ],
        village_id: [
          { required: true, message: 'Kelurahan harus diisi', trigger: 'change' }
        ],
        detail_address: [
          { required: true, message: 'Alamat Lengkap harus diisi', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: 'Titik lokasi harus diisi', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: 'Longitude harus diisi', trigger: 'blur' }
        ]
      },
      loading: true,
      onSubmit: false,
      defaultPosition: { lat: -2.548926, lng: 118.0148634 },
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }
    }
  },
  computed: {
    ...mapState('Region', ['provinces', 'cities', 'subdistricts', 'villages']),
    ...mapState('User/address', ['userAddress', 'address']),
    ...mapState('GoogleMaps', ['location'])
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Region', [
      'getProvinces',
      'getCities',
      'getSubdistricts',
      'getVillages'
    ]),
    ...mapActions('User/address', ['addUserAddress', 'editUserAddress', 'getUserAddress', 'getUserDetailAddress']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    ...mapActions('GoogleMaps', ['getLocation']),
    async initComponent () {
      await this.getProvinces()
      if (this.type === 'edit') {
        this.initDataEdit()
      } else {
          await this.getUserAddress()
          this.formData.main = !this.userAddress.length
          this.loading = false
      }
    },
    initFormData () {
      return Object.assign({
        title: '',
        recipient_name: '',
        phone_number: '',
        province: '',
        city: '',
        subdistrict: '',
        village_id: '',
        postal_code: '',
        detail_address: '',
        note: '',
        main: false,
        latitude: '',
        longitude: ''
      })
    },
    async initDataEdit () {
        this.loading = true
        try {
            const resp = await this.getUserDetailAddress(this.$route.params.id)
            if (resp.data.status_code === 200) {
                this.formData = {
                    title: this.address.title,
                    recipient_name: this.address.recipient_name,
                    phone_number: this.address.phone_number,
                    province: this.address.village.subdistrict.city.province.id,
                    city: this.address.village.subdistrict.city.id,
                    subdistrict: this.address.village.subdistrict.id,
                    village_id: this.address.village.id,
                    postal_code: this.address.village.postal_code,
                    detail_address: this.address.detail_address,
                    note: this.address.note,
                    main: this.address.main,
                    latitude: this.address.latitude,
                    longitude: this.address.longitude
                }
                await this.handleGetCities()
                await this.handleGetSubdistricts()
                await this.handleGetVillages()
                await this.handleChangePosition({
                      latLng: {
                                lat: Number(this.formData.latitude),
                                lng: Number(this.formData.longitude)
                              }
                      }, false)
          }
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false
        }
    },
    async handleChange (target) {
      if (target === 'province') {
        await this.handleGetCities()
        this.formData.city = ''
        this.formData.subdistrict = ''
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'city') {
        await this.handleGetSubdistricts()
        this.formData.subdistrict = ''
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'subdistrict') {
        await this.handleGetVillages()
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'village_id') {
        this.villages.map((village) => {
          if (village.id === Number(this.formData.village_id)) {
            this.formData.postal_code = village.postal_code
          }
        })
      }
      this.getDefaultMap()
    },
    async handleGetCities () {
        try {
          await this.getCities({
            data: {
              where: {
                province_id: this.formData.province
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
    },
    async handleGetSubdistricts () {
        try {
          await this.getSubdistricts({
            data: {
              where: {
                city_id: this.formData.city
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
    },
    async handleGetVillages () {
        try {
          await this.getVillages({
            data: {
              where: {
                subdistrict_id: this.formData.subdistrict
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
    },
    handleChangePosition (event, setLatLng = true) {
      if (setLatLng) {
        this.formData.latitude = event.latLng.lat().toString() || ''
        this.formData.longitude = event.latLng.lng().toString() || ''
      }
      this.$refs.markerLocation.$markerObject.setPosition(event.latLng)
    },
    getDefaultMap () {
      if (
        this.formData.province &&
        this.formData.city &&
        this.formData.subdistrict &&
        this.formData.village_id &&
        this.formData.postal_code &&
        this.formData.detail_address
      ) {
        this.getDefaultLocation()
      }
    },
    async getDefaultLocation () {
      const province = this.provinces.filter((province) => {
        return province.id === Number(this.formData.province)
      })[0].name
      const city = this.cities.filter((city) => {
        return city.id === Number(this.formData.city)
      })[0]
      const subdistrict = this.subdistricts.filter((subdistrict) => {
        return subdistrict.id === Number(this.formData.subdistrict)
      })[0].name
      const village = this.villages.filter((village) => {
        return village.id === Number(this.formData.village_id)
      })[0].name
      const string =
        this.formData.detail_address +
        ', ' +
        village +
        ', ' +
        subdistrict +
        ', ' +
        city.type +
        ' ' +
        city.name +
        ', ' +
        province +
        ', ' +
        this.formData.postal_code

      try {
        const resp = await this.getLocation({
          data: {
            address: string
          }
        })

        if (resp.status === 200) {
          this.formData.latitude =
            resp.data.data.results[0].geometry.location.lat
          this.formData.longitude =
            resp.data.data.results[0].geometry.location.lng
          this.defaultPosition.lat =
            resp.data.data.results[0].geometry.location.lat
          this.defaultPosition.lng =
            resp.data.data.results[0].geometry.location.lng
          this.handleChangePosition({
            latLng: {
              lat: this.formData.latitude,
              lng: this.formData.longitude
            }
          }, false)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.onSubmit = true
          try {
            let resp = {}
            if (this.type === 'edit') {
              resp = await this.editUserAddress({ payload: { data: this.formData }, id: this.$route.params.id })
            } else {
              resp = await this.addUserAddress({ data: this.formData })
            }
            if (resp.data.status_code === 201 || resp.data.status_code === 200) {
              this.$message({
                ...defaultToastOptions(),
                message: `Alamat berhasil di${this.type === 'edit' ? 'ubah' : 'tambahkan'}`,
                type: toastTypes.success.value
              })
              this.$router.push('/account/address')
            }
          } catch (error) {
            console.log(error)
            this.$Swal.fire({
              ...defaultSwal2Options(),
              icon: swal2Icons.error,
              title: 'Gagal',
              text: getResponseErrorMessage(error),
              heightAuto: false
            })
          } finally {
            this.onSubmit = false
          }
        }
      })
    }
  }

}
