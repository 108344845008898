//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      voucherSelected: [],
      errorMessages: [],
      inputCode: '',
      valid: '',
      indexVoucher: 0,
      voucher: {},
      errorMessage: '',
      onSubmit: false,
      onCheck: false,
      action: 'choose',
      modalOpened: false
    }
  },
  computed: {
    ...mapState('Cart', ['selectItem', 'cart', 'cartAvailability']),
    ...mapGetters('Cart', ['getPaymentDetail']),
    ...mapGetters('Voucher', ['listVouchers', 'voucherUsedId']),
    ...mapState('Voucher', [
      'vouchers',
      'showDetail',
      'voucherUsed',
      'pageVoucher',
      'totalPageVoucher'
    ]),
    ...mapState('Checkout', [
      'selectedAddress',
      'branchSelected',
      'listItem',
      'storeBranch',
      'storeBranchPAS',
      'pageTakeInStore',
      'usePoint',
      'selectedCourier'
    ]),
    ...mapState('Checkout', {
      checkoutPaymentDetail: 'paymentDetail'
    }),
    cartIds () {
      const tempData = []
      if (this.$route.name === 'cart') {
        this.selectItem.map((selectId) => {
          this.cart.map((c) => {
            c.cart_items.map((items) => {
              if (items.id === selectId) {
                tempData.push(items.id)
              }
            })
          })
        })
      } else {
        this.listItem.available.map((item) => {
          tempData.push(item.id)
        })
      }

      return tempData
    },
    totalDiscountVoucher () {
      let total = 0

      if (this.$route.path === '/checkout') {
        total = this.checkoutPaymentDetail && Object.keys(this.checkoutPaymentDetail).length ? this.checkoutPaymentDetail.total_discount_voucher : 0
      } else {
        total = this.getPaymentDetail.total_discount_voucher
      }

      return total
    },
    selectedCount () {
      return this.voucherSelected ? this.voucherSelected.length : 0
    },
    getWithoutText () {
      let msg = ''

      if (!this.selectedCount) {
        msg = 'Lanjutkan Tanpa Voucher'
      } else {
        const errorLength = this.errorMessages.length
        const res = errorLength % this.selectedCount

        if ((res === 1)) {
          msg = 'Lanjutkan dengan 1 Voucher'
        } else if ((this.errorMessage && this.selectedCount && !this.errorMessages.length)) {
          msg = 'Lanjutkan dengan 1 Voucher'
        } else {
          msg = 'Lanjutkan Tanpa Voucher'
        }
      }

      return msg
    },
    isCartPage () {
      return this.$route.path === '/cart'
    },
    inputVoucher () {
      if (this.voucherSelected.length) {
        const inputVoucher = this.voucherSelected.filter((select) => {
          return select.selectType === 'input'
        })

        if (inputVoucher && inputVoucher.length) {
          return inputVoucher[0]
        }
      }
      return {}
    }
  },
  watch: {
    inputCode () {
      if (!this.inputCode) {
        this.errorMessage = ''
      }
    },
    listItem () {
      if (this.listItem.available && !this.listItem.available.length) {
        this.voucherSelected = []
        this.setVoucherUsed([])
        localStorage.removeItem('voucher')
      }
    },
    async selectItem () {
      if (this.$route.name === 'cart') {
        if (!this.selectItem.length) {
          this.voucherSelected = []
          this.setVoucherUsed([])
          localStorage.removeItem('voucher')
        } else if (this.selectItem.length && this.voucherSelected.length) {
          await this.handleCheckVoucher()
          this.handleSetVoucherUsed()
        }
      }
    },
    async voucherSelected () {
      if (this.voucherSelected.length) {
        await this.handleCheckVoucher()
      } else {
        this.valid = ''
        this.errorMessages = []
        this.resetVouchersByType()
      }
    },
    voucherUsed () {
      if (!this.voucherUsed.length) {
        this.voucherSelected = []
        this.valid = ''
      } else {
        setTimeout(() => {
          this.valid = 'success'
        }, 500)
      }
    }
  },
  mounted () {
    const myModalEl = document.getElementById('modalVouchers')

    const vm = this
    myModalEl.addEventListener('show.bs.modal', async (event) => {
      await vm.checkVoucherOnOpenModal()
    })
  },
  methods: {
    ...mapActions('Voucher', ['getVouchersByType', 'showVoucher', 'checkVoucher']),
    ...mapActions('Cart', ['getCartAvailability']),
    ...mapActions('Checkout', ['getItemAvailability']),
    ...mapMutations('Voucher', [
      'setVouchers',
      'setVoucher',
      'setOnCheck',
      'setVoucherUsed',
      'setToggleDetail',
      'clearVouchersByType',
      'addVoucherAfterCode',
      'editVouchersByType',
      'resetVouchersByType'
    ]),
    handleInputCode (value) {
      const code = value ? value.toUpperCase() : ''
      this.inputCode = code
    },
    async checkVoucherOnOpenModal () {
      const vouchers = []

      if (this.listVouchers.length) {
        this.listVouchers.forEach((voucher) => {
          vouchers.push(
            ...voucher.data.map((dt) => {
              return dt.id
            })
          )
        })

        const onlyExist = this.voucherUsed.filter((selected) => {
          return vouchers.includes(selected.id)
        })

        this.voucherSelected = JSON.parse(JSON.stringify(onlyExist))
        this.setVoucherUsed(this.voucherSelected)
        localStorage.voucher = JSON.stringify(this.voucherSelected)

        if (this.$route.path === '/checkout') {
          await this.handleCheckoutAvailability()
        }

        if (!onlyExist.length) {
          this.valid = ''
        }
      }
    },
    async getListVouchers (type = 'discount', page = '1') {
      this.onCheck = true

      try {
        await this.getVouchersByType({
          type,
          data: {
            cart_id: this.cartIds,
            voucher_id_used: this.voucherUsedId,
            paginated: true,
            only_available: false,
            page,
            per_page: 2
          },
          params: {
            page,
            per_page: 2
          }
        })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
      this.onCheck = false
    },
    disabledVoucher (vou, sectionType, vouIdx) {
      if (this.voucherSelected && this.voucherSelected.length) {
        if (this.pageTakeInStore) {
          return this.voucherSelected.some((select) => {
            return (select.id !== vou.id) && sectionType === 'free_shipping_cost'
          })
        } else {
          return this.voucherSelected.some((select) => {
            return ((select.id !== vou.id) && (sectionType === select.type))
          })
        }
      } else {
        return this.onCheck || this.$route.name === 'cart'
          ? !this.selectItem.length || !vou.active
          : !vou.active
      }
    },
    async handleCheckVoucher () {
      try {
        this.onCheck = true
        const latestVoucher = this.voucherSelected[this.voucherSelected.length - 1]

        const usedVoucher = this.voucherSelected.filter((select) => {
          return select.code !== latestVoucher.code
        }).map((select) => {
          return select.id
        })

        const payload = {
          data: {
            voucher_code: latestVoucher.code,
            voucher_id_used: usedVoucher,
            cart_id: this.cartIds,
            pickup_at_store: this.isCartPage ? false : this.pageTakeInStore
          }
        }

        const resp = await this.checkVoucher(payload)
        this.setErrorMessages(resp.data.message, resp.data.data.available, latestVoucher)
        if (!resp.data.data.available) {
          this.valid = 'error'
        } else {
          if (!this.errorMessages.length && !this.errorMessage) {
            this.valid = 'success'
          }

          const selectedIdx = this.voucherSelected.findIndex((selected) => {
            return selected.id === latestVoucher.id
          })

          if (selectedIdx > -1) {
            this.voucherSelected[selectedIdx] = Object.assign(this.voucherSelected[selectedIdx], {
              available: resp.data.data.available,
              total_discount: resp.data.data.total_discount
            })
          }

          if (this.isCartPage) {
            await this.handleCartAvailabiliy()
          } else {
            await this.handleCheckoutAvailability()
          }
        }
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.onCheck = false
      }
    },
    setErrorMessages (message, available, voucher) {
      const idSelected = this.voucherSelected.map((selected) => {
        return selected.id
      })
      const errorIdx = this.errorMessages.findIndex((errorVoucher) => {
        return !idSelected.includes(errorVoucher.id)
      })

      if (errorIdx > -1) {
        this.errorMessages.splice(errorIdx, 1)
      }

      if (!available) {
        const errVoucherIdx = this.errorMessages.findIndex((errorVoucher) => {
          return voucher.id === errorVoucher.id
        })

        if (errVoucherIdx > -1) {
          this.errorMessages[errVoucherIdx].errorMessage = message
        } else {
          const errorObj = {
            ...voucher,
            errorMessage: message
          }

          this.errorMessages.push(errorObj)
        }
      }
    },
    getErrorMessage (voucherId) {
      if (this.errorMessages.length) {
        const errorIndex = this.errorMessages.findIndex((selected) => {
          return selected.id === voucherId
        })

        if (errorIndex > -1) {
          return this.errorMessages[errorIndex].errorMessage
        }
      }

      return ''
    },
    async handleCartAvailabiliy () {
      try {
        const payload = {
          data: {
            cart_id: this.cartIds,
            voucher_id: this.voucherSelected.map((selected) => {
              return selected.id
            }),
            use_paninti_point: this.usePoint
          }
        }

        await this.getCartAvailability(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
    },
    closeModal (event, data) {
      event.stopPropagation()
      event.preventDefault()
      this.setToggleDetail(true)
      this.$refs.closeModalVouchers.click()
    },
    // active, voucher, idx, value, search
    async handleCheckoutAvailability () {
      this.setOnCheck(true)
      this.onCheck = true

      const cartItem = this.cartIds
      const storeId = this.storeBranch.length || this.storeBranchPAS.length
        ? this.getDataBranch()[this.branchSelected].id
        : null
      const address = this.selectedAddress ? this.selectedAddress.id : null
      const courierCode = Object.keys(this.selectedCourier).length
        ? this.selectedCourier.code
        : null
      const courierService = Object.keys(this.selectedCourier).length
        ? this.selectedCourier.service
        : null
      const voucherId = this.voucherSelected.map((selected) => {
        return selected.id
      })

      try {
        if (!this.showDetail) {
          const resp = await this.checkAvailibilities(
            cartItem,
            storeId,
            address,
            courierCode,
            courierService,
            this.pageTakeInStore,
            voucherId,
            this.usePoint
          )

          const vouchers = resp.data.data.checkout.vouchers
          const availableVoucher = vouchers.filter((voucher) => {
            return voucher.available === true
          })

          const unavailableVoucher = vouchers.filter((voucher) => {
            return voucher.available === false
          })

          if (
            this.voucherUsed.length &&
            vouchers.length &&
            unavailableVoucher.length
          ) {
            if (availableVoucher.length) {
              this.$message({
                message: 'Hanya bisa menggunakan 1 voucher',
                type: 'warning',
                showClose: true
              })
              this.valid = 'success'
            } else {
              this.valid = 'error'
              this.$message({
                message: unavailableVoucher[0].message,
                type: 'error',
                showClose: true
              })
            }
          } else {
            this.valid = 'success'
          }
        }
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.setOnCheck(false)
        this.onCheck = false
      }
    },
    handleSetVoucherUsed () {
      this.setVoucherUsed(this.voucherSelected)
      localStorage.voucher = JSON.stringify(this.voucherSelected)
    },
    handleDestroy () {
      if (!this.voucherUsed.length) {
        this.voucherSelected = []
        this.valid = ''
        this.inputCode = ''
      }
    },
    async handleContinueWithoutVoucher () {
      const availableVoucher = this.voucherSelected.filter((selected) => {
        if (this.inputCode && this.errorMessage) {
          return selected.code !== this.inputCode
        } else {
          return this.errorMessages.some((errorVoucher) => {
            return errorVoucher.id !== selected.id
          })
        }
      })

      if (availableVoucher && availableVoucher.length) {
        this.voucherSelected = availableVoucher
        this.setVoucherUsed(availableVoucher)
        this.inputCode = ''
        this.errorMessage = ''
        this.errorMessages = []
        localStorage.voucher = JSON.stringify(availableVoucher)
      } else {
        this.voucherSelected = []
        this.setVoucherUsed([])
        this.valid = ''
        this.inputCode = ''
        this.errorMessage = ''
        this.errorMessages = []
        localStorage.voucher = JSON.stringify(this.voucherSelected)

        await this.handleCheckoutAvailability()
      }
    },
    async handleClickSearch () {
      this.action = 'search'
      this.onCheck = true
      this.onSubmit = true

      const checkVoucherUsed = this.voucherSelected.some((select) => {
        return select.code === this.inputCode.toUpperCase()
      })

      if (checkVoucherUsed) {
        this.onCheck = false
        this.onSubmit = false
        this.$message({
          message: 'Voucher Sudah Dipilih!',
          type: 'error',
          showClose: true
        })
        return false
      }

      const usedVoucherId = this.voucherSelected.map((select) => {
        return select.id
      })

      const payload = {
        data: {
          voucher_code: this.inputCode,
          voucher_id_used: usedVoucherId,
          cart_id: this.cartIds,
          pickup_at_store: this.isCartPage ? false : this.pageTakeInStore
        }
      }

      this.errorMessage = ''

      try {
        const resp = await this.checkVoucher(payload)

        if (!resp.data.data.available) {
          this.errorMessage = resp.data.message

          this.valid = 'error'

          const inputVoucherIdx = this.voucherSelected.findIndex((select) => {
            return select.selectType === 'input'
          })

          if (inputVoucherIdx > -1) {
            this.voucherSelected.splice(inputVoucherIdx, 1)
          }
        } else {
          if (!this.errorMessages.length) {
            this.valid = 'success'
          }

          // check same voucher
          const selectedIdx = this.voucherSelected.findIndex((selected) => {
            return selected.id === resp.data.data.voucher.id
          })

          if (selectedIdx > -1) {
            const previousData = JSON.parse(JSON.stringify(this.voucherSelected[selectedIdx]))
            const modified = Object.assign(previousData, {
              available: resp.data.data.available,
              total_discount: resp.data.data.total_discount,
              selectType: 'input'
            })

            this.voucherSelected[selectedIdx] = JSON.parse(JSON.stringify(modified))

            const payloadEdit = {
              type: modified.type === 'discount' ? 'vouchersDiscount' : 'vouchersShipping',
              voucherId: modified.id,
              additionalData: {
                available: resp.data.data.available,
                total_discount: resp.data.data.total_discount,
                selectType: 'input'
              }
            }
            this.editVouchersByType(payloadEdit)
          } else {
            const voucher = {
              ...resp.data.data.voucher,
              available: resp.data.data.available,
              total_discount: resp.data.data.total_discount,
              active: true,
              selectType: 'input'
            }

            this.addVoucherAfterCode({
              voucher,
              type: voucher.type === 'discount' ? 'vouchersDiscount' : 'vouchersShipping'
            })

            const payloadEdit = {
              type: voucher.type === 'discount' ? 'vouchersDiscount' : 'vouchersShipping',
              voucherId: voucher.id,
            additionalData: {
                ...resp.data.data.voucher,
                available: resp.data.data.available,
                total_discount: resp.data.data.total_discount,
                active: true,
                selectType: 'input'
              }
            }
            this.editVouchersByType(payloadEdit)

            const newVoucher = JSON.parse(JSON.stringify(this.voucherSelected))
            this.voucherSelected = [
              ...newVoucher,
              voucher
            ]
          }
        }
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
        const inputVoucherIdx = this.voucherSelected.findIndex((select) => {
          return select.selectType === 'input'
        })

        if (inputVoucherIdx > -1) {
          this.voucherSelected.splice(inputVoucherIdx, 1)
        }
      } finally {
        this.onSubmit = false
        this.onCheck = false
      }
    },
    async handleShowVoucher ({ event, voucherId }) {
      await this.getShowVoucher(voucherId)
      this.closeModal(event)
      setTimeout(() => {
        this.$refs.openDetailVoucher.click()
      }, 500)
    },
    async getShowVoucher (voucherId) {
      try {
        const payload = {
          voucherId
        }

        await this.showVoucher(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    }
  }
}
