//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    const validatorPassword = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

      if (!value.match(regex)) {
        callback(new Error('Password minimal berisi 1 huruf kapital dan 1 angka'))
      }
      if (value === ' ') {
        this.formRegister.password = ''
      }
      if (input === ' ') {
        this.formRegister.password = value.substr(0, value.length - 1)
      }
      callback()
    }
    return {
      onSubmit: false,
      formChangePassword: {
        token: this.$route.params.token,
        password: '',
        password_confirmation: ''
      },
      rules: {
        password: [
          { required: true, message: 'Kata sandi wajib diisi', trigger: 'blur' },
          { min: 6, message: 'Kata sandi minimal 6 karakter', trigger: 'blur' },
          { validator: validatorPassword }
        ],
        password_confirmation: [
          { required: true, message: 'Konfirmasi kata sandi wajib diisi', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.formRegister.password) {
                callback(new Error('Kata sandi tidak sesuai'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('Auth', ['resetPassword']),
    async submit() {
      this.onSubmit = true;
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          this.onSubmit = false;
          return;
        }
        try {
          const resp = await this.resetPassword({
            data: this.formChangePassword
          });
          if (resp.status === 200) {
            await this.$Swal.fire({
              icon: 'success',
              title: 'Berhasil',
              text: 'Password berhasil diubah!'
            });
            window.location = this.$route.query.redirect;
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error;
          this.$Swal.fire({
            icon: 'error',
            title: 'Oops, ada kesahalan.',
            text: errorMessage,
            heightAuto: false
          });
        }
      });
      this.onSubmit = false;
    },
  },
};
