var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-comp mb-5 mb-lg-0 p-0"},[_c('h3',{staticClass:"paninti-h3 color-text-paragraph paninti-mb-24"},[_vm._v("\n    "+_vm._s(_vm.isVerif ? 'Buat Kata Sandi Baru' : 'Ubah Kata Sandi')+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"paninti-content-base color-text-paragraph paninti-mb-16"},[_vm._v("\n    "+_vm._s(_vm.isVerif ? 'Kata sandi minimal 6 karakter, mengandung 1 huruf kapital, dan 1 angka. Pastikan kata sandi disimpan dengan baik dan tidak diberikan pada orang lain.' : 'Masukkan kata sandi akun Anda untuk melanjutkan ke perubahan kata sandi baru')+"\n  ")]),_vm._v(" "),_c('client-only',[_c('el-form',{ref:"editPasswordForm",staticClass:"d-flex flex-wrap flex-column paninti-form",attrs:{"model":_vm.formData}},[_c('div',{staticClass:"col-12 col-lg-6"},[(!_vm.isVerif)?[_c('el-form-item',{attrs:{"prop":"current_password","rules":[
              {
                required: !_vm.isVerif,
                message: 'Masukan kata sandi saat ini',
                trigger: 'blur'
              },
              {
                min: 6,
                message: 'Kata sandi minimal 6 karakter',
                trigger: 'blur'
              }
            ],"error":_vm.errorCurrentPassword}},[_c('label',{staticClass:"paninti-content-base semibold color-text-paragraph paninti-mb-8",attrs:{"for":"name"}},[_vm._v("Kata Sandi Lama")]),_vm._v(" "),_c('el-input',{staticClass:"w-100",attrs:{"type":"password","placeholder":"Masukan kata sandi lama","show-password":""},on:{"input":_vm.clearValidate},model:{value:(_vm.formData.current_password),callback:function ($$v) {_vm.$set(_vm.formData, "current_password", $$v)},expression:"formData.current_password"}})],1),_vm._v(" "),_c('a',{staticClass:"paninti-content-base semibold color-text-link-primary paninti-mb-16",attrs:{"href":"/forgot-password"}},[_vm._v("\n            Lupa kata sandi?\n          ")])]:[_c('el-form-item',{attrs:{"prop":"new_password","rules":[
              {
                required: _vm.isVerif,
                message: 'Masukan kata sandi baru',
                trigger: 'blur'
              },
              {
                min: 6,
                message: 'Kata sandi baru minimal 6 karakter',
                trigger: 'blur'
              },
              {
                validator: function () {
                            var args = [], len = arguments.length;
                            while ( len-- ) args[ len ] = arguments[ len ];

                            return _vm.validatorPassword.apply(void 0, args);
}
              }
            ]}},[_c('label',{staticClass:"paninti-content-base semibold color-text-paragraph paninti-mb-8",attrs:{"for":"name"}},[_vm._v("Kata Sandi Baru")]),_vm._v(" "),_c('el-input',{attrs:{"type":"password","placeholder":"Masukan kata sandi baru","show-password":""},model:{value:(_vm.formData.new_password),callback:function ($$v) {_vm.$set(_vm.formData, "new_password", $$v)},expression:"formData.new_password"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"confirm_new_password","rules":[
              {
                required: _vm.isVerif,
                message: 'Masukan konfirmasi kata sandi baru',
                trigger: 'blur'
              },
              {
                min: 6,
                message: 'Konfirmasi kata sandi baru minimal 6 karakter',
                trigger: 'blur'
              },
              {
                validator: function () {
                            var args = [], len = arguments.length;
                            while ( len-- ) args[ len ] = arguments[ len ];

                            return _vm.validatorPassword.apply(void 0, args);
}
              }
            ]}},[_c('label',{staticClass:"paninti-content-base semibold color-text-paragraph paninti-mb-8",attrs:{"for":"name"}},[_vm._v("Konfirmasi Kata Sandi Baru")]),_vm._v(" "),_c('el-input',{attrs:{"type":"password","placeholder":"Masukan konfirmasi kata sandi baru","show-password":""},model:{value:(_vm.formData.confirm_new_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_new_password", $$v)},expression:"formData.confirm_new_password"}})],1)]],2)])],1),_vm._v(" "),_c('button',{staticClass:"paninti-btn paninti-btn-primary paninti-px-36 paninti-mt-16",attrs:{"disabled":_vm.onSubmit},on:{"click":function($event){_vm.isVerif ? _vm.handleSubmit() : _vm.handleVerif()}}},[_vm._v("\n    "+_vm._s(_vm.isVerif ? 'Simpan' : 'Verifikasi')+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }