//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    variant: {
      type: String,
      default: 'border-rounded'
    },
    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      tabVariant: {
        default: 'default',
        defaultRounded: 'default-rounded',
        defaultWithBorder: 'default-with-border',
        defaultRightLine: 'default-right-line',

        selectedBottomLine: 'selected-bottom-line',
        selectedRightLine: 'selected-right-line',

        filled: 'filled',
        filledRounded: 'filled-rounded'
      }
    }
  }
}
