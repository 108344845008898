//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { affiliateBalanceWithdrawalStatuses } from '@/utils/helpers/affiliate-balance-withdrawal-helper'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    affiliateBalanceWithdrawalStatuses () {
      return affiliateBalanceWithdrawalStatuses
    }
  },

  methods: {
    formatDateWithTimezone,
    formatRupiah,

    handleClick () {
      this.$emit('click', this.item.id)
    }
  }
}
