//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultPaginationAttributes } from '~/utils/helpers/pagination-helper'
import { generateRandomString } from '~/utils/helpers/string-helper'
import { cardProductPurposes } from '~/utils/helpers/product-helper'

export default {
  props: {
    affiliateProductsData: {
      type: Object,
      default: () => ({
        ...defaultPaginationAttributes()
      })
    },

    // old
    section: {
      type: String,
      default: ''
    }
  },

  computed: {
    cardProductPurposes () {
      return cardProductPurposes
    }
  },

  methods: {
    generateRandomString,

    handleInfiniteLoadingListAffiliateProducts ($state) {
      this.$emit('infinite-loading', $state)
    },

    handleDeleteAffiliateItem (deletedData) {
      this.$emit('affiliate-product-deleted', deletedData)
    },

    handleBtnShareClicked (shareData) {
      this.$emit('btn-share-card-product-clicked', shareData)
    },

    completeInfiniteLoadingListAffiliateProducts () {
      this.$refs.infiniteLoadingListAffiliateProducts.stateChanger.complete()
    },

    resetInfiniteLoadingListAffiliateProducts () {
      this.$refs.infiniteLoadingListAffiliateProducts.stateChanger.reset()
    }
  }
}
