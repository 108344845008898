//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { filterInputOnlyNumber, filterLettersAndCapitalize, validateForm } from '@/utils/helpers/form-helper'
import { accountTypes } from '@/utils/helpers/account-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  props: {
    masterBanksData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes()
      })
    },
    createUserBankAccountData: {
      type: Object,
      default: () => ({
        loading: false
      })
    }
  },

  data () {
    return {
      formBankAccount: {
        bank: '',
        accountNumber: '',
        onBehalfOf: ''
      },

      formBankAccountRules: {
        bank: [
          {
            required: true,
            message: 'Bank wajib dipilih',
            trigger: ['change', 'blur']
          }
        ],
        accountNumber: [
          {
            required: true,
            message: 'Nomor rekening wajib diisi',
            trigger: ['change', 'blur']
          }
        ],
        onBehalfOf: [
          {
            required: true,
            message: 'Nama pemegang wajib diisi',
            trigger: ['change', 'blur']
          }
        ]
      },
      onSubmit: false
    }
  },

  methods: {
    generateRandomString,

    closeModal () {
      this.$refs.modalBase.closeModal()
    },

    handleModalClosed () {
      this.resetFormBankAccount()
      this.$emit('modal-closed')
    },

    resetFormBankAccount () {
      this.$refs.formCreateUserBankAccount.resetFields()
    },

    handleInputAccountNumber (value) {
      this.formBankAccount.accountNumber = filterInputOnlyNumber(value)
    },

    handleInputOnBehalfOf (value) {
      this.formBankAccount.onBehalfOf = filterLettersAndCapitalize(value, false)
    },

    async submitFormCreateUserBankAccount () {
      const validationResult = await validateForm(this.$refs.formCreateUserBankAccount)
      if (!validationResult) { return }

      this.$emit('btn-submit-click', {
        data: {
          name: null,
          owner_name: this.formBankAccount.onBehalfOf,
          value: {
            account_number: this.formBankAccount.accountNumber
          },
          master_bank_id: this.formBankAccount.bank,
          type: accountTypes.bank
        }
      })
    }
  }
}
