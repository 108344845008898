//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateRandomString } from '~/utils/helpers/string-helper'

export default {
  data () {
    return {
      guidelineContents: [
        {
          content: 'Cari produk yang ingin direkomendasikan',
          active: false
        },
        {
          content: 'Pada halaman detail produk, klik bagikan dan salin link',
          active: false
        },
        {
          content: 'Bagikan link ke berbagai platform sosial mediamu',
          active: false
        },
        {
          content: 'Dapatkan komisi untuk setiap penjualan yang selesai dari link yang dibagikan',
          active: false
        }
      ]
    }
  },

  methods: {
    generateRandomString,

    handleClickGuidelineContent (selectedIndex) {
      this.guidelineContents = this.guidelineContents.map((guidelineContent, index) => {
        if (index === selectedIndex) {
          return {
            ...guidelineContent,
            active: !guidelineContent.active
          }
        }

        return {
          ...guidelineContent,
          active: false
        }
      })
    }
  }
}
