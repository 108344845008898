//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

import panstoreMixins from '@/mixins'

import { changeToBase64 } from '@/utils/helper'

export default {
    mixins: [panstoreMixins],

    layout: 'invoice',

    data () {
        return {
            isPrint: false,
            loading: false,

            invoiceOriginalName: 'Invoice Paninti',
            invoice64: null,
            invoiceObjectUrl: null,

            isDownload: false,
            isAvailableIds: false,
            payload: {}
        }
    },

    mounted () {
        this.getAndSetInvoiceFile()
    },

    methods: {
        ...mapActions('Order', ['downloadInvoice']),

        async getAndSetInvoiceFile () {
            if (!this.$route.query.id) {
                this.$router.go(-1)
            }

            this.isAvailableIds = true
            this.loading = true

            try {
                const response = await this.downloadInvoice(
                    this.$route.params.order_id || null
                )
                const { base64data, originalFilename } = await changeToBase64(response.data, response.headers)
                this.invoiceOriginalName = originalFilename
                this.invoice64 = base64data
                this.invoiceObjectUrl = URL.createObjectURL(response.data)
            } catch (err) {
                console.log(err)

                const errorMessage = err.response ? err.response.data.message : err
                await this.$Swal.fire({
                    icon: 'error',
                    title: 'Gagal Menampilkan Invoice',
                    text: errorMessage
                })

                this.$router.go(-1)
            }

            const heightNavbar = document.getElementById('invoice-header')
            const pdfViewer = document.getElementById('pdf-viewer')
            pdfViewer.style.height = `calc(100vh - ${heightNavbar.offsetHeight}px)`

            this.loading = false
        },

        handleDownload () {
            const link = document.createElement('a')
            link.href = this.invoice64
            link.download = this.invoiceOriginalName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.handleChangeStatus()
        },

        handlePrint () {
            const iframe = document.createElement('iframe')
            iframe.style.display = 'none'
            iframe.src = this.invoiceObjectUrl
            document.body.appendChild(iframe)

            iframe.onload = () => {
                iframe.contentWindow.print()
            }

            window.addEventListener('afterprint', () => {
                URL.revokeObjectURL(this.invoiceObjectUrl)
            })
            this.handleChangeStatus()
        },

        async handleChangeStatus () {
            try {
                await this.bulkUpdateIsPrinted({ data: this.payload })
            } catch (error) {
                console.log(error)
            }
        },

        handleRendered () {
            const wrapper = document.querySelector('.end')
            wrapper.querySelector('.icon-btn').classList.add('d-none')
            document.querySelector('.start').querySelector('.title').innerHTML = this.invoiceOriginalName
        }
    }
}
