//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import panstoreMixin from '@/mixins'

export default {
  mixins: [panstoreMixin],

  data () {
    return {
      loading: true,
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },

  computed: {
    ...mapState('Flashsale', ['flashsale']),
    isMobile () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    countDownFlashsale () {
      if (this.getFlashTime(this.flashsale[0].date_start)) {
        return this.countdownTime(this.flashsale[0].date_end)
      } else {
        return this.countdownTime(this.flashsale[0].date_start)
      }
    },
    sectionStyle () {
      if (this.flashsale.length) {
        const eventData = this.flashsale[0].event_sale
        const bgFlashsale = eventData.files.find((file) => {
          return file.name === (this.isMobile ? 'background_image_mobile' : 'background_image_desktop')
        })

        if (bgFlashsale && Object.keys(bgFlashsale).length) {
          return `
            background-image: url("${bgFlashsale.value}");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `
        }
      }

      return ''
    }
  },

  mounted () {
    this.getFlashSaleOngoingSessions()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    ...mapActions('Flashsale', ['getOngoingSession']),
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getFlashSaleOngoingSessions () {
      try {
        await this.getOngoingSession({
          data: {
            where_has: [
              {
                relation: 'event_sale',
                where: {
                  type: 'flash_sale'
                }
              }
            ]
          }
        })
      } catch (error) {
        this.$message.error('Gagal mendapatkan data flash sale')
      }
    },

    countdownEnd () {
      window.location.reload()
    },

    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },

    getFlashTime (start) {
      /*eslint-disable */
      //convert to MM-DD-YYYY
      let tempStart =
        start.substr(3, 2) +
        "-" +
        start.substr(0, 2) +
        "-" +
        start.substr(6, 4) +
        start.substr(10, 9);
      let startTime = this.$dayjs(tempStart);

      if (new Date(startTime).toString() === "Invalid Date") {
        const dateNew = new Date(
          Number(start.substr(6, 4)),
          Number(start.substr(3, 2)) - 1,
          Number(start.substr(0, 2)),
          Number(start.substr(11, 2)),
          Number(start.substr(14, 2)),
          Number(start.substr(17, 2))
        );
        startTime = this.$dayjs(dateNew);
      }

      const dateNow = this.$dayjs(this.convertTZ(new Date()));

      return dateNow.isAfter(startTime);
    },

    getFlashSaleStartedAtText () {
      let flashSaleStartedAtText = ''

      if (this.flashsale.length > 0) {
        flashSaleStartedAtText = !this.getFlashTime(this.flashsale[0].date_start)
          ? "Dimulai Pada " + this.getFSHours(this.flashsale[0].date_start)
          : "Sedang Berlangsung"
      }

      return flashSaleStartedAtText
    }
  },
};
