//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    direction: {
      type: String,
      default: 'horizontal'
    },
    width: {
      type: String,
      default: '1px'
    },
    height: {
      type: String,
      default: '1px'
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
