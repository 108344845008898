//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  mixins: [panstoreMixin],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    storePartner: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    productsLength: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('User/address', ['location', 'userAddress', 'isCreated']),
    detailExists () {
      return this.item.details && this.item.details.length
    },
    onFlashsale () {
      if (this.detailExists && this.item.details[0].event_sale) {
        return this.getFlashTime(this.item.details[0].event_sale.date_start)
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations('Product', ['setShareUrl']),
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant', 'setIsBuyNow']),
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),
    ...mapActions('Product', ['addProductAffiliate']),
    toProduct (event) {
      event.stopPropagation()
      this.$router.push('/product/' + this.item.slug)
    },
    async handleAddCart (item, buynow = false, event) {
      event.stopPropagation()
      await this.setSelectedProduct(item)

      this.setIsBuyNow(buynow)

      if (item.type === 'simple') {
        const payload = Object.assign({
          data: {
            is_fulfilled: item.is_fulfilled || false,
            store_id: item.store.id,
            product_detail_id: item.details[0].id,
            quantity: 1,
            session_id: this.$cookies.get('session_id')
          }
        })

        try {
          const resp = await this.addCart(payload)
          localStorage.removeItem('cartItem')
          if (resp.data.status_code === 201) {
            if (!buynow) {
              await this.setSelectedVariant(null)
              await this.handleGetCart()
              this.$refs.showAlert.click()
            } else {
              const cartItem = []
              cartItem.push(resp.data.data.cart.id)
              localStorage.cartItem = JSON.stringify(cartItem)

              const route = this.$auth.loggedIn ? '/checkout' : '/login'
              setTimeout(() => {
                this.$router.push(route)
              }, 200)
            }
          } else {
            this.$message({
              showClose: true,
              message: resp.data.message,
              type: 'error'
            })
          }
        } catch (error) {
          console.log(error)
          const errorMessage = error.response
            ? error.response.data.message
            : error
          this.$message({
            showClose: true,
            message: errorMessage,
            type: 'error'
          })
        }
      } else {
        this.$refs.showModal.click()
      }
    },
    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }
      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }
      try {
        await this.getCartsNavbar({ params: payload })
      } catch (error) {
        console.log(error)
      }
    },
    handleBuyNow (event) {
      event.stopPropagation()
      if (this.$auth.loggedIn) {
        if (
          this.$auth.user.main_address ||
          this.userAddress.length
        ) {
          this.handleAddCart(this.item, true, event)
        } else {
          this.$Swal
            .fire({
              title: 'Anda belum memiliki alamat',
              text:
                'Silahkan tambah alamat untuk melanjutkan ke proses checkout',
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Tambah alamat'
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$refs.buttonCreateAddress.click()
              }
            })
        }
      } else {
        this.$router.push(`/login?redirect_from=${this.$route.path}`)
      }
    },
    async joinAffiliate () {
      const payload = Object.assign({
        data: {
          product_ids: [this.item.id]
        }
      })
      try {
        await this.addProductAffiliate(payload)
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil Menambahkan Produk',
            text: ' Produk berhasil ditambahkan ke daftar afiliasi. Promosikan tautan referalmu untuk mendapatkan komisi dari Paninti',
            heightAuto: false
          })
      } catch (error) {
        console.log(error)
        this.$Swal
          .fire({
            icon: 'error',
            title: 'Gagal Menambahkan Produk',
            text: error,
            heightAuto: false
          })
      }
    },
    handleSetShareUrl (url) {
      this.setShareUrl(url)
    }
  }
}
