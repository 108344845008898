//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import panstoreMixin from '@/mixins/index'
import cookiesMixin from '@/mixins/cookies-mixin'

export default {
  mixins: [panstoreMixin, cookiesMixin],

  computed: {
    ...mapState('Product', ['product']),

    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },

  created () {
    this.setCookies()
  }
}
