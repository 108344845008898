import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5c485ae2&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=5c485ae2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c485ae2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,WelcomePageAffiliate: require('/usr/src/app/components/account/affiliate/WelcomePageAffiliate.vue').default,DashboardAffiliate: require('/usr/src/app/components/account/affiliate/DashboardAffiliate.vue').default})
