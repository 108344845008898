//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    const validatorEmail = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const letters = /^[A-Za-z0-9._+@]+$/
      if (value === ' ') {
        this.formLogin.email = ''
      }

      if (value.length > 100) {
        this.formLogin.email = value.substring(0, value.length - 1)
      }

      if (!input.match(letters)) {
        this.formLogin.email = value.substring(0, value.length - 1)
      }

      callback()
    }
    return {
      onSubmit: false,
      form: null,
      formLogin: {
        email: '',
        password: '',
        remember: false,
        session_id: this.$cookies.get('session_id')
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Username atau email wajib diisi',
            trigger: 'blur'
          },
          {
            validator: validatorEmail
          }
        ]
      }
    }
  },
  methods: {
    async submit() {
      this.onSubmit = true
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const resp = await this.$auth.loginWith('local', {
              data: this.formLogin
            })
            if (resp.status === 200) {
              window.location.href = this.$route.query.redirect_from ? this.$route.query.redirect_from : "/"
              this.$cookies.remove('session_id')
            }
          } catch (error) {
            const errorMessage = error.response
              ? error.response.data.message
              : error
            this.$Swal.fire({
              icon: 'error',
              title: 'Gagal Login',
              text: errorMessage,
              heightAuto: false
            })
            this.onSubmit = false
          }
        }
      })
      this.onSubmit = false
    },
  }
}
