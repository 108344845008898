//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      inputCode: ''
    }
  },
  computed: {
    ...mapState('Cart', ['selectItem']),
    ...mapState('Checkout', ['pageTakeInStore']),
    ...mapState('Voucher', ['voucher', 'voucherUsed']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    isCartPage () {
      return this.$route.path === '/cart'
    },
    termAndConditions () {
      return this.voucher.term_and_conditions || this.voucher.term_and_condition
    },
    usedCondition () {
      return this.selectItem.length && (this.$route.path === '/cart' || this.$route.path === '/checkout')
    }
  },
  methods: {
    ...mapMutations('Voucher', ['setToggleDetail', 'setVoucherUsed', 'editVouchersByType']),
    ...mapActions('Checkout', ['getItemAvailability']),
    ...mapActions('Voucher', ['checkVoucher']),
    closeModal () {
      this.$refs.closePopup.click()
      this.setToggleDetail(false)
    },
    async handleCheck () {
      const checkExist = this.voucherUsedId.includes(this.voucher.id)

      if (!checkExist) {
        try {
          const payload = {
            data: {
              voucher_code: this.voucher.code,
              voucher_id_used: this.voucherUsedId,
              cart_id: this.selectItem,
              pickup_at_store: this.isCartPage ? false : this.pageTakeInStore
            }
          }
          const resp = await this.checkVoucher(payload)
          if (resp.data.data.available) {
            const data = Object.assign({
              ...resp.data.data.voucher,
              active: true,
              available: resp.data.data.available,
              total_discount: resp.data.data.total_discount,
              selectType: 'choose'
            })

            const payloadEdit = {
              type: data.type === 'discount' ? 'vouchersDiscount' : 'vouchersShipping',
              voucherId: data.id,
              additionalData: {
                available: resp.data.data.available,
                total_discount: resp.data.data.total_discount,
                selectType: 'choose'
              }
            }
            this.editVouchersByType(payloadEdit)

            const modifiedVoucher = [
              ...this.voucherUsed,
              {
                ...data
              }
            ]

            this.setVoucherUsed(modifiedVoucher)
            localStorage.voucher = JSON.stringify(modifiedVoucher)
            this.setToggleDetail(false)
            this.$refs.closeModalVouchers.click()
          } else {
            this.$message({
              message: resp.data.message,
              type: 'error',
              showClose: true
            })
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error
          this.$message({
            message: errorMessage,
            type: 'error',
            showClose: true
          })
        }
      } else {
        this.$message({
          message: 'Voucher Sudah Digunakan',
          type: 'error',
          showClose: true
        })
      }
    },
    getImage (type) {
      if (type === 'discount') {
        return require('@/static/img/home/cart/voucher-tag.svg')
      } else {
        return require('@/static/img/home/cart/voucher-truck.svg')
      }
    },
    getPeriode () {
      const dateStart = this.$dayjs(this.formatYMD(this.voucher.date_start))
      const dateFinish = this.$dayjs(this.formatYMD(this.voucher.date_end))
      let result = '-'
      if (this.voucher.date_start && this.voucher.date_end) {
        if (
          dateStart.get('M') === dateFinish.get('M') &&
          dateStart.get('y') === dateFinish.get('y')
        ) {
          result =
            dateStart.format('DD') + '-' + dateFinish.format('DD MMMM YYYY')
          dateFinish.get('y')
        } else if (dateStart.get('M') !== dateFinish.get('M')) {
          result =
            dateStart.format('DD MMMM') +
            ' - ' +
            dateFinish.format('DD MMMM YYYY')
        } else {
          result =
            dateStart.format('DD MMMM YYYY') +
            ' - ' +
            dateFinish.format('DD MMMM YYYY')
        }
      }

      return result
    },
    handleAlert () {
      this.$message({
        message: 'Kode voucher berhasil disalin ke clipboard',
        type: 'success',
        showClose: true
      })
    }
  }
}
