//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import {
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes
} from '@/utils/helpers/pagination-helper'
import { getResponseErrorMessage, isResponseInternalServerError } from '@/utils/helpers/response-helper'
import { accountTypes } from '@/utils/helpers/account-helper'
import { maxNumberOfBankAccount } from '@/utils/helpers/user-helper'

export default {
  data () {
    return {
      getMasterBanksData: {
        ...defaultNotPaginationAttributes()
      },

      getUserBankAccountsData: {
        ...defaultNotPaginationAttributes(),
        body: {
          type: accountTypes.bank,
          order_by: {
            created_at: 'asc'
          }
        }
      },

      createUserBankAccountData: {
        loading: false
      }
    }
  },

  computed: {
    ...mapState('BankAccount', ['bankAccounts']),

    isAmountOfUserBankAccountReachLimit () {
      return this.getUserBankAccountsData.data ? this.getUserBankAccountsData.data.length >= maxNumberOfBankAccount : false
    }
  },

  async mounted () {
    await this.getUserBankAccountsForListData()
    this.getMasterBanksForCreateUserBankAccountData()
  },

  methods: {
    ...mapActions('BankAccount', ['getMasterBanks', 'getBankAccounts', 'createBankAccount']),

    async getMasterBanksForCreateUserBankAccountData () {
      try {
        const response = await this.getMasterBanks({
          data: {
            paginated: false
          }
        })

        assignResponseToDataModelDataNotPaginated(this.getMasterBanksData, response.data.data.banks)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    },

    async getUserBankAccountsForListData () {
      try {
        this.getUserBankAccountsData.loading = true

        const resp = await this.getBankAccounts({
          data: {
            paginated: this.getUserBankAccountsData.paginated,
            ...this.getUserBankAccountsData.body
          }
        })

        assignResponseToDataModelDataNotPaginated(this.getUserBankAccountsData, resp.data.data.accounts)
      } catch (error) {
        console.log(error)
        this.$message.error(getResponseErrorMessage(error))
      } finally {
        this.getUserBankAccountsData.loading = false
      }
    },

    async handleBtnSubmitCreateUserBankAccountClick (payload) {
      try {
        this.createUserBankAccountData.loading = true

        await this.createBankAccount({
          data: payload.data
        })

        await this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Berhasil menambahkan akun bank!'
          })
          .then(async () => {
            this.$refs.modalCreateBankAccount.resetFormBankAccount()
            this.$refs.modalCreateBankAccount.closeModal()
            await this.handleListUserBankAccountChange()
          })
      } catch (error) {
        console.log(error)

        await this.$Swal.fire({
          icon: 'error',
          title: 'Gagal Menambahkan Akun Bank',
          text: !isResponseInternalServerError(error)
            ? getResponseErrorMessage(error)
            : 'Gagal menambahkan akun bank. Mohon tunggu beberapa saat dan coba kembali.'
        })
      } finally {
        this.createUserBankAccountData.loading = false
      }
    },

    async handleListUserBankAccountChange () {
      await this.getUserBankAccountsForListData()
    }
  }
}
