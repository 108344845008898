import { render, staticRenderFns } from "./change-password.vue?vue&type=template&id=4b9d58e2&scoped=true&"
import script from "./change-password.vue?vue&type=script&lang=js&"
export * from "./change-password.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9d58e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,FormEditPassword: require('/usr/src/app/components/account/profile/FormEditPassword.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
