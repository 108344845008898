//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { affiliateCommissionBalanceTypes } from '@/utils/helpers/affiliate-commission-helper'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    affiliateCommissionBalanceTypes () {
      return affiliateCommissionBalanceTypes
    }
  },

  methods: {
    formatDateWithTimezone,
    formatRupiah,

    handleClick () {
      this.$emit('click', this.item.id)
    }
  }
}
