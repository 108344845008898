//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapState } from 'vuex'
import momentTimezone from 'moment-timezone'
import dateTimeMixin from '@/mixins/datetime-mixin'

export default {
  mixins: [panstoreMixin, dateTimeMixin],
  layout: 'affiliate',
  data () {
    return {
    }
  },
  computed: {
    ...mapState('Base', ['csPhone']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    },
    threeDaysAgoAfterAffiliateRegistrationRejected () {
      if (!this.user) { return '' }
      if (!this.user.affiliate_registration) { return '' }
      if (!this.user.affiliate_registration.status_updated_at) { return '' }

      const threeDaysAgo = momentTimezone.tz(
        this.user.affiliate_registration.status_updated_at, 'DD-MM-YYYY HH:mm:ss', this.defaultTimeZoneName
      )
      if (!threeDaysAgo.isValid()) { return '' }

      return threeDaysAgo
        .tz(momentTimezone.tz.guess())
        .add(3, 'days').add(1, 'minute')
        .locale('id').format('D MMMM YYYY HH:mm')
    },
    page () {
      if (!this.$route.query.page) {
        return 1
      } else {
        return Number(this.$route.query.page)
      }
    }
  },
  mounted () {
    this.checkRegisteredAffiliateUser()
    this.checkPage()
  },
  methods: {
    checkPage () {
      if (this.page === 2) {
        if (!this.user.email_verified_at) {
          this.$router.push({
            query: {
              page: 1
            }
          })
        }
      } else if (this.page === 3) {
        if (!this.checkArray(this.user.social_medias)) {
          this.$router.push({
            query: {
              page: 2
            }
          })
        }
      }
    },
    checkRegisteredAffiliateUser () {
      if (this.checkObject(this.user)) {
        const messageItem = {
          title: '',
          message: '',
          redirect: '',
          link: '',
          external: false,
          actionText: ''
        }

        if (this.checkObject(this.user.affiliate)) {
          messageItem.title = 'Anda Sudah Terdaftar Program Afiliasi'
          messageItem.message = 'Akun Anda sudah terdaftar sebagai Affiliates. Anda bisa mengecek profil untuk mengelola produk dan komisi Afiliasi Anda'
          messageItem.redirect = '/account/affiliate'
          messageItem.actionText = 'Lihat Profil'
        } else if (this.checkObject(this.user.affiliate_registration) &&
          this.user.affiliate_registration.status === 'new') {
          messageItem.title = 'Pengajuan Afiliasi Sedang Diproses'
          messageItem.message = 'Akun Anda sudah mengajukan sebagai Affiliates Pengajuan akan diproses maksimal 7 hari kerja. Anda bisa menghubungi Layanan Pelanggan kami untuk informasi lebih lengkap..'
          messageItem.link = `https://api.whatsapp.com/send?phone=${this.csPhone}&text=Saya ingin bertanya mengenai status pengajuan afiliasi saya`
          messageItem.external = true
          messageItem.redirect = '/'
          messageItem.actionText = 'Hubungi Layanan Pelanggan'
        } else if (this.checkObject(this.user.affiliate_registration) &&
          this.user.affiliate_registration.status === 'rejected' &&
          !this.user.affiliate_registration.is_available) {
          messageItem.title = 'Pengajuan Afiliasi Ditolak'
          messageItem.message = `
            Pengajuan Anda ditolak,
            dengan alasan “${this.user.affiliate_registration.rejection_reason}”.
            Anda dapat kembali mendaftar pada ${this.threeDaysAgoAfterAffiliateRegistrationRejected}
          `
          messageItem.link = `https://api.whatsapp.com/send?phone=${this.csPhone}&text=Saya ingin bertanya mengenai penolakan afiliasi saya`
          messageItem.external = true
          messageItem.redirect = '/affiliate'
          messageItem.actionText = 'Hubungi Layanan Pelanggan'
        }

        if (!messageItem.title) {
          return
        }

        this.$Swal.fire({
          icon: 'info',
          title: messageItem.title,
          text: messageItem.message,
          confirmButtonText: messageItem.actionText
        }).then((confirm) => {
          if (confirm.value) {
            if (!messageItem.external) {
              this.$router.push(messageItem.redirect)
            } else {
              this.$router.push(messageItem.redirect)
              window.open(messageItem.link)
            }
          } else {
            this.$router.push('/')
          }
        })
      }
    }
  }
}
