//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah, generateRandomString } from '@/utils/helpers/string-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'

export default {
  props: {
    affiliateCommissionHistory: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      listCommissionProductsCollapse: ['1']
    }
  },

  computed: {
    affiliateCommissionOrder () {
      return this.affiliateCommissionHistory.order || null
    },

    commissionHistoryCreatedAt () {
      return this.affiliateCommissionOrder ? this.affiliateCommissionOrder.created_at : null
    }
  },

  methods: { generateRandomString, formatDateWithTimezone, formatRupiah }
}
