//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper';
import { swal2Icons, defaultSwal2Options } from '@/utils/helpers/swal2-helper';

export default {
  props: {
    address: {
      type: Object,
      default: () => {}
    },
    className: {
      type: String,
      default: ''
    }
  },

  methods: {
    ...mapActions('User/address', ['editUserAddress', 'deleteUserAddress']),

    async handleChangeMainAddress (addressId) {
      try {
        await this.editUserAddress({
          id: addressId,
          payload: {
            data: {
              main: true
            }
          }
        })
        this.$message({
          ...defaultToastOptions(),
          message: 'Alamat berhasil dijadikan alamat utama',
          type: toastTypes.success.value
        })
        this.$emit('get-data')
      } catch (error) {
        this.$message({
          ...defaultToastOptions(),
          message: 'Alamat gagal dijadikan alamat utama',
          type: toastTypes.error.value
        })
      }
    },
    handleDelete (item) {
      this.$Swal.fire({
        ...defaultSwal2Options(),
        icon: swal2Icons.question,
        title: 'Hapus Alamat',
        text: 'Apakah Anda yakin ingin menghapus alamat ini ?',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const resp = await this.deleteUserAddress({ data: { id: [item.id] } })
            if (resp.status === 200) {
              this.$message({
                ...defaultToastOptions(),
                message: 'Alamat berhasil dihapus',
                type: toastTypes.success.value
              })
              this.$emit('get-data')
            }
          } catch (error) {
            this.$message({
              ...defaultToastOptions(),
              message: 'Gagal menghapus alamat, silahkan coba beberapa saat lagi',
              type: toastTypes.error.value
            })
          }
        }
      })
    }
  }
}
