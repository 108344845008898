import { render, staticRenderFns } from "./ListAffiliateProducts.vue?vue&type=template&id=9c87de00&scoped=true&"
import script from "./ListAffiliateProducts.vue?vue&type=script&lang=js&"
export * from "./ListAffiliateProducts.vue?vue&type=script&lang=js&"
import style0 from "./ListAffiliateProducts.vue?vue&type=style&index=0&id=9c87de00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c87de00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardProduct: require('/usr/src/app/components/home/base/CardProduct.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default,EmptyState: require('/usr/src/app/components/base/EmptyState.vue').default})
