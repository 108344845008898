//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'PopularProducts',
  mixins: [panstoreMixin],

  data () {
    return {
      perPage: 4
    }
  },
  computed: {
    ...mapState('Product', ['popularAffiliateProduct'])
  },
  async mounted () {
    await this.handleGetPopularProduct()
  },
  methods: {
    ...mapMutations('Product', ['resetPopularAffiliateProduct']),
    ...mapActions('Product', ['getPopularAffiliateProduct']),
    async handleGetPopularProduct () {
      try {
        const payload = {
          data: {
            paginated: false,
            limit: 4,
            per_page: this.perPage,
            order_by: 'most_popular'
          }
        }

        await this.getPopularAffiliateProduct(payload)
      } catch (error) {}
    },
    async handleDeleteAffiliateItem () {
      await this.handleGetPopularProduct()
    }
  }
}
