//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapMutations, mapActions } from 'vuex'
import { swal2Icons, defaultSwal2Options } from '@/utils/helpers/swal2-helper';
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper';
export default {
  layout: 'account',
  middleware: ['user'],
  mixins: [mixins],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Kartu Kredit/Debit'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      selectedItem: {},
      onCreate: false,
      loading: true
    }
  },
  computed: {
    ...mapState('User', ['accounts'])
  },
  async mounted () {
    await this.handleGetPayment()
  },
  methods: {
    ...mapMutations('User', ['setCreateCC']),
    ...mapActions('User', ['getPayment', 'deletePayment']),
    async handleGetPayment () {
      try {
        this.loading = true
        const payload = {
          data: {
            paginated: true,
            per_page: 10,
            page: 1,
            where: {
              type: 'Credit Card'
            }
          }
        }

        await this.getPayment(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        console.log(errorMessage)
      } finally {
        this.loading = false
      }
      this.loading = false
    },
    showLabel (value) {
      let str = ''
      const count = value.length
      if (value) {
        str = '**** **** *' + value.substring(count - 3, count)
      } else {
        str = value
      }
      return str
    },
    handleShowItem (item) {
      this.selectedItem = item
    },
    handleAddNew () {
      this.setCreateCC(true)
      setTimeout(() => this.setCreateCC(false), 300)
    },
    handleDelete (item) {
      this.$Swal.fire({
        ...defaultSwal2Options(),
        icon: swal2Icons.question,
        title: 'Hapus Kartu',
        text: 'Apakah Anda yakin ingin menghapus kartu ini ?',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const resp = await this.deletePayment({ data: { id: [item.id] } })
            if (resp.status === 200) {
              this.$message({
                ...defaultToastOptions(),
                message: 'Kartu kredit/debit berhasil dihapus',
                type: toastTypes.success.value
              })
              await this.handleGetPayment()
            }
          } catch (error) {
            this.$message({
              ...defaultToastOptions(),
              message: 'Gagal menghapus kartu kredit/debit, silahkan coba beberapa saat lagi',
              type: toastTypes.error.value
            })
          }
        }
      })
    }
  }
}
