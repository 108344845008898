//
//
//
//
//

export default {
  props: {
    iconClass: {
      type: String,
      default: 'paninti-fill-pink-80'
    }
  }
}
