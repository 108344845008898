var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-bottom d-flex"},[_c('div',{staticClass:"col-12 col-md-7 d-md-block d-none"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-1 text-center"},[(!Object.keys(_vm.detailProduct).length)?_c('img',{staticClass:"img-product",attrs:{"src":_vm.getThumbnailImage(
              _vm.product.type === 'simple'
                ? _vm.product.files
                : _vm.product.details[0].files,
              'image'
            ),"alt":_vm.product.name},on:{"error":_vm.getDefaultImgProduct}}):_c('img',{staticClass:"img-product",attrs:{"src":_vm.getThumbnailImage(_vm.detailProduct.files, 'image'),"alt":_vm.product.name},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),_c('div',{staticClass:"col-10 my-auto"},[_c('div',{staticClass:"paninti-content-base color-text-paragraph"},[_vm._v("\n          "+_vm._s(_vm.product.name)+"\n        ")]),_vm._v(" "),(_vm.product.type === 'combination')?_c('div',{staticClass:"paninti-content-sm semibold color-text-sub paninti-mt-4"},[_vm._v("\n          "+_vm._s(_vm.detailProduct.name)+"\n        ")]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5 my-auto"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"\n          col-lg-5 col-md-6 col-12\n          my-auto\n          d-flex d-md-block\n          mb-3 mb-md-0\n        "},[_c('div',{staticClass:"paninti-content-base color-text-paragraph col-6 col-md-12"},[_vm._v("\n          Harga\n        ")]),_vm._v(" "),_c('div',{staticClass:"paninti-h4 color-navy-80 col-6 col-md-12 text-end text-md-start"},[(_vm.detailProduct && Object.keys(_vm.detailProduct).length)?[_vm._v("\n            "+_vm._s(_vm.formatRupiah(_vm.detailProduct.discounted_price.toString(), "Rp"))+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.formatRupiah(_vm.product.discounted_price.toString(), "Rp"))+"\n          ")]],2)]),_vm._v(" "),_c('div',{staticClass:"col-lg-7 col-md-6 col-12 my-auto"},[_c('div',{staticClass:"d-flex gap-2"},[_c('div',{staticClass:"col-6 px-1"},[_c('button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"paninti-btn paninti-btn-primary justify-content-center w-100",attrs:{"disabled":_vm.isLoading || Object.keys(_vm.detailProduct).length
                  ? !_vm.detailProduct.stock
                  : !_vm.product.stock},on:{"click":function($event){return _vm.addToCart(false)}}},[_vm._v("\n              + Keranjang\n            ")])]),_vm._v(" "),_c('div',{staticClass:"col-6 px-1"},[_c('button',{staticClass:"paninti-btn paninti-btn-primary-outline justify-content-center w-100",attrs:{"disabled":_vm.isLoading || Object.keys(_vm.detailProduct).length
                  ? !_vm.detailProduct.stock
                  : !_vm.product.stock},on:{"click":function($event){return _vm.handleBuyNow()}}},[_vm._v("\n              Beli Sekarang\n            ")])])])])])]),_vm._v(" "),_c('button',{ref:"showAlert",staticClass:"d-none",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#alertCart"}}),_vm._v(" "),_c('button',{ref:"buttonCreateAddress",staticClass:"d-none",attrs:{"data-bs-toggle":"modal","data-bs-target":"#formCreateAddress"}})])}
var staticRenderFns = []

export { render, staticRenderFns }