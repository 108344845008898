//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions, mapMutations } from 'vuex'

export default {
  mixins: [mixins],

  props: {
    partner: {
      type: Object,
      default: () => {}
    },

    showcases: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      sections: [],
      query: {},
      infiniteId: +new Date(),
      savedState: null
    }
  },

  computed: {
    visibleSections () {
      return this.sections.filter((sc) => {
        return sc.visible === true
      })
    },

    sectionId () {
      return (
        this.sections.filter((sc) => {
          return sc.value === this.$route.query.section
        })[0].id || null
      )
    },

    activeSectionIndex () {
      let activeSectionIndex = -1

      this.sections.map((section, index) => {
        if (section.value === String(this.$route.query.section)) {
          activeSectionIndex = index
        }
      })

      return activeSectionIndex
    },

    activeSection () {
      return this.activeSectionIndex >= 0 ? this.sections[this.activeSectionIndex] : {}
    },

    totalProductInActiveSection () {
      return this.activeSection.product ? this.activeSection.product.total : 0
    }
  },

  watch: {
    '$route.query' (newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return
      }

      if (this.activeSectionIndex >= 0) {
        if (newVal.sort !== oldVal.sort || newVal.partnerSearch !== oldVal.partnerSearch) {
          this.sections[this.activeSectionIndex].product.page = 1
          this.sections[this.activeSectionIndex].product.totalPage = 0
          this.sections[this.activeSectionIndex].product.total = 0
          this.sections[this.activeSectionIndex].product.data = []

          this.infiniteId += 1
          if (this.savedState) {
            this.savedState.reset()
          }
        }
      }

      if (this.activeSection.product && this.activeSection.product.page <= 1) {
        this.getSectionProducts()
      }
    },

    showcases () {
      this.handleSetShowcase()
    }
  },

  mounted () {
    this.handleSetShowcase()

    setTimeout(() => {
      this.getSectionProducts()
    }, 500)
  },

  methods: {
    ...mapActions('Product', [
      'getHomeProducts',
      'getProductsSale',
      'getProductsEventSale',
      'getProductSection'
    ]),
    ...mapMutations('Product', [
      'setTotalProducts'
    ]),
    handleSetShowcase () {
      let showcases = [
        {
          name: 'Semua Produk',
          value: 'all',
          visible: true,
          product: {
            isLoading: true,
            perPage: 12,
            page: 1,
            totalPage: 0,
            total: 0,
            data: []
          }
        },
        {
          name: 'Terakhir Dilihat',
          value: 'lastseen',
          visible: false,
          product: {
            isLoading: true,
            perPage: 12,
            page: 1,
            totalPage: 0,
            total: 0,
            data: []
          }
        }
      ]

      if (this.showcases.length) {
        this.showcases.map((sc) => {
          showcases.push({
            name: sc.name,
            value: sc.type === 'product' ? this.slugtify(sc.name) : sc.type,
            id: sc.type === 'product' ? sc.id : sc.event_sale_session_id,
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          })
        })
      } else {
        showcases = showcases.concat([
          {
            name: 'Eventsale',
            value: 'event_sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          },
          {
            name: 'Flashsale',
            value: 'flash_sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          },
          {
            name: 'Lagi Diskon',
            value: 'sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          },
          {
            name: 'CLEARANCE SALE',
            value: 'clearance_sale',
            visible: true,
            product: {
              isLoading: true,
              perPage: 12,
              page: 1,
              totalPage: 0,
              total: 0,
              data: []
            }
          }
        ])
      }

      this.sections = showcases
    },

    async handleInfiniteLoading ($state) {
      this.savedState = $state
      await this.getSectionProducts()

      if (this.activeSection.product.page > this.activeSection.product.totalPage) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },

    async getSectionProducts () {
      switch (String(this.$route.query.section)) {
        case 'event_sale':
          await this.handleGetEventSale()
          break
        case 'clearance_sale':
          await this.handleGetEventSale()
          break
        case 'sale':
          await this.handleGetSale()
          break
        case 'flash_sale':
          await this.handleGetEventSale()
          break
        case 'all':
          await this.handleGetProducts()
          break
        case 'lastseen':
          await this.handleGetLastSeenProducts()
          break
        default:
          await this.handleGetShowcase()
          break
      }
    },

    setQuery () {
      let query = {}

      if (Object.keys(this.$route.query).length) {
        if (this.$route.query.partnerSearch) {
          query = {
            ...query,
            search: this.$route.query.partnerSearch
          }
        }

        if (this.$route.query.sort) {
          switch (this.$route.query.sort) {
            case 'most_expensive':
              query = Object.assign({
                ...query,
                order_by: {
                  price: 'desc'
                }
              })
              break
            case 'cheapest':
              query = Object.assign({
                ...query,
                order_by: {
                  price: 'asc'
                }
              })
              break
            case 'best_seller':
              query = Object.assign({
                ...query,
                order_by: {
                  total_sold_last_month: 'desc'
                }
              })
              break
            case 'lastest':
              query = Object.assign({
                ...query,
                order_by: {
                  created_at: 'desc'
                }
              })
              break
          }
        }
      }

      this.query = query
    },

    async handleGetEventSale () {
      if (this.activeSectionIndex >= 0) {
        this.sections[this.activeSectionIndex].product.isLoading = true
        this.setQuery()

        try {
          const resp = await this.getProductsEventSale({
            data: {
              ...this.query,
              where: {
                event_sale_session_id: this.sectionId
              },
              where_has: [
                {
                  relation: 'product_detail.product',
                  where: {
                    'products.store_id': this.partner.id
                  }
                }
              ],
              page: this.sections[this.activeSectionIndex].product.page,
              per_page: this.sections[this.activeSectionIndex].product.perPage
            }
          })

          this.setTotalProducts(resp.data.data.event_sale_products.total)
          this.sections[this.activeSectionIndex].product.data = [
            ...this.sections[this.activeSectionIndex].product.data,
            ...resp.data.data.event_sale_products.data.map(esp => esp.product)
          ]
          this.sections[this.activeSectionIndex].product.totalPage = resp.data.data.event_sale_products.last_page
          this.sections[this.activeSectionIndex].product.total = resp.data.data.event_sale_products.total
          this.sections[this.activeSectionIndex].product.page++

          if (
            this.sections[this.activeSectionIndex].product.page >
            this.sections[this.activeSectionIndex].product.totalPage &&
            this.$refs['InfiniteLoading' + this.activeSectionIndex]
          ) {
            if (Array.isArray(this.$refs['InfiniteLoading' + this.activeSectionIndex])) {
              this.$refs['InfiniteLoading' + this.activeSectionIndex][0].stateChanger.complete()
            } else {
              this.$refs['InfiniteLoading' + this.activeSectionIndex].stateChanger.complete()
            }
          }
        } catch (error) {
          this.$message.error(`Gagal mendapatkan data section ${this.activeSection.name || ''} produk`)
        } finally {
          this.sections[this.activeSectionIndex].product.isLoading = false
        }
      }
    },

    async handleGetSale () {
      if (this.activeSectionIndex >= 0) {
        this.sections[this.activeSectionIndex].product.isLoading = true
        this.setQuery()

        try {
          const resp = await this.getProductsSale({
            data: {
              ...this.query,
              where: {
                'products.store_id': this.partner.id
              },
              page: this.sections[this.activeSectionIndex].product.page,
              per_page: this.sections[this.activeSectionIndex].product.perPage
            }
          })

          this.setTotalProducts(resp.data.data.products.total)
          this.sections[this.activeSectionIndex].product.data = [
            ...this.sections[this.activeSectionIndex].product.data,
            ...resp.data.data.products.data
          ]
          this.sections[this.activeSectionIndex].product.totalPage = resp.data.data.products.last_page
          this.sections[this.activeSectionIndex].product.total = resp.data.data.products.total
          this.sections[this.activeSectionIndex].product.page++

          if (
            this.sections[this.activeSectionIndex].product.page >
            this.sections[this.activeSectionIndex].product.totalPage &&
            this.$refs['InfiniteLoading' + this.activeSectionIndex]
          ) {
            if (Array.isArray(this.$refs['InfiniteLoading' + this.activeSectionIndex])) {
              this.$refs['InfiniteLoading' + this.activeSectionIndex][0].stateChanger.complete()
            } else {
              this.$refs['InfiniteLoading' + this.activeSectionIndex].stateChanger.complete()
            }
          }
        } catch (error) {
          this.$message.error(`Gagal mendapatkan data section ${this.activeSection.name || ''} produk`)
        } finally {
          this.sections[this.activeSectionIndex].product.isLoading = false
        }
      }
    },

    async handleGetProducts () {
      if (this.activeSectionIndex >= 0) {
        this.sections[this.activeSectionIndex].product.isLoading = true
        this.setQuery()

        try {
          const resp = await this.getHomeProducts({
            data: {
              ...this.query,
              where: {
                'products.store_id': this.partner.id
              },
              page: this.sections[this.activeSectionIndex].product.page,
              per_page: this.sections[this.activeSectionIndex].product.perPage
            }
          })

          this.setTotalProducts(resp.data.data.products.total)
          this.sections[this.activeSectionIndex].product.data = [
            ...this.sections[this.activeSectionIndex].product.data,
            ...resp.data.data.products.data
          ]

          this.sections[this.activeSectionIndex].product.totalPage = resp.data.data.products.last_page
          this.sections[this.activeSectionIndex].product.total = resp.data.data.products.total
          this.sections[this.activeSectionIndex].product.page++

          if (
            (this.sections[this.activeSectionIndex].product.page >
            this.sections[this.activeSectionIndex].product.totalPage) &&
            this.$refs['InfiniteLoading' + this.activeSectionIndex]
          ) {
            if (Array.isArray(this.$refs['InfiniteLoading' + this.activeSectionIndex])) {
              this.$refs['InfiniteLoading' + this.activeSectionIndex][0].stateChanger.complete()
            } else {
              this.$refs['InfiniteLoading' + this.activeSectionIndex].stateChanger.complete()
            }
          }
        } catch (error) {
          this.$message.error(`Gagal mendapatkan data section ${this.activeSection.name || ''} produk`)
        } finally {
          this.sections[this.activeSectionIndex].product.isLoading = false
        }
      }
    },

    async handleGetLastSeenProducts () {
      if (this.activeSectionIndex >= 0) {
        this.sections[this.activeSectionIndex].product.isLoading = true
        this.setQuery()

        try {
          const resp = await this.getProductSection({
            section: 'last-seen',
            payload: {
              data: {
                ...this.query,
                where: {
                  'products.store_id': this.partner.id
                },
                page: this.sections[this.activeSectionIndex].product.page,
                per_page: this.sections[this.activeSectionIndex].product.perPage
              }
            }
          })

          this.setTotalProducts(resp.data.data.products.total)
          this.sections[this.activeSectionIndex].product.data = [
            ...this.sections[this.activeSectionIndex].product.data,
            ...resp.data.data.products.data
          ]
          this.sections[this.activeSectionIndex].product.totalPage = resp.data.data.products.last_page
          this.sections[this.activeSectionIndex].product.total = resp.data.data.products.total
          this.sections[this.activeSectionIndex].product.page++

          if (
            this.sections[this.activeSectionIndex].product.page >
            this.sections[this.activeSectionIndex].product.totalPage &&
            this.$refs['InfiniteLoading' + this.activeSectionIndex]
          ) {
            if (Array.isArray(this.$refs['InfiniteLoading' + this.activeSectionIndex])) {
              this.$refs['InfiniteLoading' + this.activeSectionIndex][0].stateChanger.complete()
            } else {
              this.$refs['InfiniteLoading' + this.activeSectionIndex].stateChanger.complete()
            }
          }
        } catch (error) {
          this.$message.error(`Gagal mendapatkan data section ${this.activeSection.name || ''} produk`)
        } finally {
          this.sections[this.activeSectionIndex].product.isLoading = false
        }
      }
    },

    async handleGetShowcase () {
      if (this.activeSectionIndex >= 0) {
        this.sections[this.activeSectionIndex].product.isLoading = true
        this.setQuery()
        const id =
          this.sections.filter((sc) => {
            return sc.value === this.$route.query.section
          })[0].id || null

        try {
          const resp = await this.getHomeProducts({
            data: {
              ...this.query,
              where_has: [
                {
                  relation: 'showcases',
                  where: {
                    id
                  }
                }
              ],
              page: this.sections[this.activeSectionIndex].product.page,
              per_page: this.sections[this.activeSectionIndex].product.perPage
            }
          })

          this.setTotalProducts(resp.data.data.products.total)
          this.sections[this.activeSectionIndex].product.data = [
            ...this.sections[this.activeSectionIndex].product.data,
            ...resp.data.data.products.data
          ]
          this.sections[this.activeSectionIndex].product.totalPage = resp.data.data.products.last_page
          this.sections[this.activeSectionIndex].product.total = resp.data.data.products.total
          this.sections[this.activeSectionIndex].product.page++

          if (
            this.sections[this.activeSectionIndex].product.page >
            this.sections[this.activeSectionIndex].product.totalPage &&
            this.$refs['InfiniteLoading' + this.activeSectionIndex]
          ) {
            if (Array.isArray(this.$refs['InfiniteLoading' + this.activeSectionIndex])) {
              this.$refs['InfiniteLoading' + this.activeSectionIndex][0].stateChanger.complete()
            } else {
              this.$refs['InfiniteLoading' + this.activeSectionIndex].stateChanger.complete()
            }
          }
        } catch (error) {
          this.$message.error(`Gagal mendapatkan data section ${this.activeSection.name || ''} produk`)
        } finally {
          this.sections[this.activeSectionIndex].product.isLoading = false
        }
      }
    },

    handleResetQuery () {
      this.$router.replace({})
    },

    handleFilterSectionChange (section) {
      this.$router.push({
        query: {
          section: section.value
        }
      })
    },

    getFlashTime (start) {
      if (start) {
        const tempStart =
          start.substr(3, 2) +
          '-' +
          start.substr(0, 2) +
          '-' +
          start.substr(6, 4) +
          start.substr(10, 9)

        const startTime = this.$dayjs(tempStart)
        const dateNow = this.$dayjs(this.convertTZ(new Date()))

        return dateNow.isAfter(startTime)
      }

      return false
    },

    isSectionActive (sectionValue) {
      return this.activeSection && this.activeSection.value === sectionValue
    }
  }
}
