//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions, mapState } from 'vuex'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
export default {
  name: 'CartPage',
  async asyncData ({ store, app }) {
    store.dispatch('Cart/actionWithCart', false)
    await store.dispatch('Cart/handlingLoading', true)

    try {
      await store.dispatch('Cart/getCarts', {
        params: {
          paginated: true,
          page: 1,
          per_page: 10,
          session_id: app.$cookies.get('session_id')
        }
      })

      await store.dispatch('Cart/getEmptyCarts', {
        params: {
          paginated: true,
          page: 1,
          per_page: 10,
          unavailable_stock: true,
          session_id: app.$cookies.get('session_id')
        }
      })

      try {
        const payloadAddress = {
          data: {
            where: {
              addressable_id: app.$auth.$state.user.id // user id
            }
          }
        }
        await store.dispatch('User/address/getUserAddress', payloadAddress)
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }
  },

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Keranjang'
        }
      ]
    }
  },

  computed: {
    ...mapState('Cart', ['carts', 'cart', 'cartEmpty']),
    ...mapState('User/address', ['userAddress']),

    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  created () {
    this.getSummaryCarts()
  },
  mounted () {
    if (localStorage.cartItem) {
      localStorage.removeItem('cartItem')
    }

    if (localStorage.note) {
      localStorage.removeItem('note')
    }

    if (localStorage.getItem('voucher')) {
      localStorage.removeItem('voucher')
    }

    this.setSelectedBranch(0)
    this.setTakeInStore(false)
  },

  methods: {
    ...mapActions('Voucher', ['getVouchersByType']),
    ...mapMutations('Checkout', ['setTakeInStore', 'setSelectedBranch']),
    ...mapActions('Cart', ['getCarts', 'getCartsNavbar', 'getEmptyCarts', 'deleteCart', 'getCartSummary', 'getEmptyCartSummary']),
    async getSummaryCarts () {
      try {
        const arrSummary = [
          this.getCartSummary({
            params: {
              unavailable_stock: false,
              session_id: this.$cookies.get('session_id')
            }
          }),
          this.getEmptyCartSummary({
            params: {
              unavailable_stock: true,
              session_id: this.$cookies.get('session_id')
            }
          })
        ]

        await Promise.all(arrSummary)
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        console.log(msg)
      }
    },
    async fetchAvailableCarts () {
      try {
        const payloadAvailable = {
          params: {
            paginated: true,
            page: 1,
            per_page: 10,
            session_id: this.$cookies.get('session_id')
          }
        }

        await this.getCarts(payloadAvailable)
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        console.log(msg)
      }
    },
    async fetchNavbarCarts () {
      try {
        const payloadCartNavbar = {
          params: {
            session_id: this.$cookies.get('session_id')
          }
        }
        await this.getCartsNavbar(payloadCartNavbar)
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        console.log(msg)
      }
    },
    async fetchEmptyCarts () {
      try {
        const payloadUnAvailable = {
          data: {
            conditions: {
              stock: 'unavailable_stock'
            },
            order_by: {
              'carts.updated_at': 'desc'
            },
            session_id: this.$cookies.get('session_id')
          }
        }
        await this.getEmptyCarts(payloadUnAvailable)
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        console.log(msg)
      }
    },
    async fetchCarts () {
      const fetchArr = [
        this.fetchNavbarCarts(),
        this.fetchEmptyCarts(),
        this.getSummaryCarts()
      ]

      await Promise.all(fetchArr)
    }
  },

  head: () => {
    return {
      title: 'Keranjang | Paninti Store'
    }
  }
}
