//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      seeMore: false,
      benefits: [
        {
          id: 1,
          img: '/img/home/footer/icon-cs.svg',
          text: '24/7 Customer Service'
        },
        {
          id: 2,
          img: '/img/home/footer/icon-store.svg',
          text: 'Bayar & Ambil di Toko Terdekat'
        },
        {
          id: 3,
          img: '/img/home/footer/icon-work-together.svg',
          text: 'Gabung Menjadi Partner'
        }
      ]
    }
  }
}
