//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions, mapState } from 'vuex'
export default {
  mixins: [mixins],
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataLink: {}
    }
  },
  computed: {
    ...mapState('Base', ['csPhone']),

    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    },

    linkLogoAffiliate () {
      return this.checkObject(this.user) && this.checkObject(this.user.affiliate)
        ? '/account/affiliate'
        : '/affiliate'
    }
  },

  created () {
    this.handleGetData()
  },

  methods: {
    ...mapActions('Contents', ['getDownloadLink']),

    async handleGetData () {
      try {
        const resp = await this.getDownloadLink()
        if (resp.status === 200) {
          this.dataLink = resp.data.data
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
