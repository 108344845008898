//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateRandomString } from '~/utils/helpers/string-helper'

export default {
  data () {
    return {
      activeNames: ['1'],

      affiliateQuestions: [
        {
          name: '1',
          title: 'Apa itu Program Afiliasi Paninti?',
          content: '<p>Pasti sudah tidak asing ketika mendengar afiliasi. Program yang ditujukan untuk mendapatkan penghasilan tambahan tanpa perlu modal yang besar. Afiliasi Paninti mengajak Anda untuk bergabung menjadi afiliator Kami dan dapatkan komisi tambahan dengan cara mempromosikan produk-produk dari Paninti. Platform yang digunakan untuk mempromosikan produk bisa apa saja seperti Instagram, Tiktok, Twitter, Facebook, ataupun sosial media lainnya yang dapat menunjang pemasaran.</p><p>Para afiliator dibebaskan untuk membuat video semenarik mungkin agar customer tertarik untuk membeli produk tersebut. Program Afiliasi Paninti ini dapat diikuti oleh semua orang tanpa minimum followers. Selama customer dapat memenuhi syarat & ketentuan yang berlaku, maka komisi akan diberikan kepada afiliator.</p>'
        },
        {
          name: '2',
          title: 'Bagaimana cara untuk bergabung menjadi Affiliates?',
          content: '<p>Ada 2 cara untuk bergabung menjadi affiliates di Paninti:</p><ol><li>Diundang atau ditawarkan pihak Paninti untuk menjadi affiliator</li><li>Mengajukan atau mendaftar untuk menjadi affiliator</li></ol>'
        },
        {
          name: '3',
          title: 'Berapa banyak komisi yang didapatkan?',
          content: '<ol><li>Afilliator yang sudah bergabung pada program Paninti Affiliate akan mendapatkan komisi sebesar hingga 10% untuk pesanan dari toko yang dipromosikan.</li><li>Komisi dapat diperoleh dari Store atau Brand Partner yang sudah bergabung di Paninti App.</li><li>Komisi akan dibayarkan langsung melalui Bank Transfer setelah pesanan terselesaikan dan Afiliator mengajukan tarik saldo.</li></ol>'
        },
        {
          name: '4',
          title: 'Apakah komisi bisa ditarik ke rekening saya?',
          content: '<p>Komisi dapat ditarik ke rekening pribadi Afiliator. Penarikan dapat dilakukan kapan saja selama saldo pada akun tersedia. Minimal penarikan saldo ke rekening pribadi Afiliator senilai Rp10.000.</p>'
        },
        {
          name: '5',
          title: 'Berapa lama program afiliasi berlangsung?',
          content: '<p>Program Afiliasi Paninti berlangsung selama periode kerjasama yang telah disepakati kedua belah pihak.</p>'
        }
      ]
    }
  },

  methods: { generateRandomString }
}
