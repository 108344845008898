//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OtpInput from '@bachdgvn/vue-otp-input';
import { mapActions } from 'vuex'

export default {
  name: 'ModalCreateAccount',
  components: {
    'v-otp-input': OtpInput
  },

  props: {
    modalId: {
      type: String,
      default: 'modalOtpCreateAccount'
    },
    fromRegister: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      countdown: 60 * 1000, // 60 sec,
      otpValid: null,
      showCountdown: false,
      otpValue: ''
    }
  },

  watch: {
    fromRegister () {
      if (this.fromRegister) {
        this.setCountdown()
      }
    }
  },

  methods: {
    ...mapActions('Auth', ['sendOtp', 'verifyOtp']),

    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },

    setCountdown () {
      this.countdown = 60 * 1000
      this.showCountdown = true
    },

    async handleSendOtp () {
      if (this.showCountdown) {
        return
      }
      try {
        const payload = {
          data: {
            purpose: 'registration'
          }
        }

        await this.sendOtp(payload)
        this.setCountdown()
        this.$message({
          message: 'Kode OTP Berhasil dikirim, silahkan cek email anda',
          type: 'success',
          showClose: true
        })
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      }
    },

    submitVerify () {
      this.handleVerifyOtp(this.otpValue)
    },

    async handleVerifyOtp (otp) {
      try {
        await this.verifyOtp({
          data: {
            purpose: 'registration',
            otp_code: otp
          }
        })

        this.otpValid = true
        this.$Swal
          .fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Verifikasi akun berhasil!'
          })
          .then((confirm) => {
            this.$refs.btnClose.click()
            window.location.href = '/affiliate/create-affiliate?page=2'
          })
      } catch (error) {
        console.log(error)
        this.otpValid = false
      }
    },

    modalClose () {
      this.$emit('modal-close')
    },

    handleOnChange (value) {
      this.otpValid = null
      this.otpValue = value
    },

    async handleOnComplete (value) {
      await this.handleVerifyOtp(value)
    }
  }
}
