//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    commissionHistoryDetail: {
      type: Object,
      default: () => ({
        loading: true,
        data: null
      })
    }
  },

  data () {
    return {
      active: ['1']
    }
  },

  computed: {
    commissionHistoryDetailData () {
      return this.commissionHistoryDetail.data
        ? {
          affiliate_balance_withdrawal: {
            ...this.commissionHistoryDetail.data,
            account: {
              ...this.commissionHistoryDetail.data.account,
              bank: this.commissionHistoryDetail.data.account
                ? this.commissionHistoryDetail.data.account.bank_name
                : null
            }
          }
        }
        : null
    }
  }
}
