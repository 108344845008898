//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: {
    modal: {
      type: String,
      default: 'show'
    },
    withoutBrand: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeNames: ['1', '2', '3', '4', '5', '6', '7'],
      activeNamesTwo: ['1', '2', '3', '4', '5', '6', '7'],
      valueRange: [0, 10000000],
      selected_category: [],
      selected_merk: [],
      selected_location: [],
      selected_color: [],
      selected_size: [],
      selected_rating: [5],
      defaultProps: {
        children: 'sub_categories',
        label: 'name'
      },
      showBrand: 10,
      showColor: 10,
      showSize: 10,
      showLocation: 10,
      search: '',
      sort: ''
    }
  },
  computed: {
    ...mapState('Product/filter', [
      'filterCategories',
      'filterBrands',
      'filterColor',
      'filterSize',
      'filterLocation',
      'loadData'
    ])
  },
  watch: {
    '$route.query' () {
      this.getData()
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      const dataQuery = this.initFilter(
        this.$route.query.brands,
        this.$route.query.search,
        this.$route.query.categories,
        this.$route.query.colors,
        this.$route.query.sizes,
        this.$route.query.priceLimit,
        this.$route.query.rating,
        this.$route.query.sort,
        this.$route.query.city
      )
      this.selected_merk = dataQuery.brands
      this.selected_category = dataQuery.category
      this.selected_color = dataQuery.colors
      this.selected_size = dataQuery.sizes
      this.selected_location = dataQuery.location
      if (dataQuery.priceLimit) {
        this.valueRange[0] = dataQuery.priceLimit[0]
        this.valueRange[1] = dataQuery.priceLimit[1]
      } else {
        this.valueRange = [0, 10000000]
      }
      if (this.selected_category === {}) {
        this.$refs.categoryTree.setCheckedKeys([])
      }
      this.search = dataQuery.search
      this.sort = dataQuery.sort
    },
    handleShowAll (target) {
      if (target === 'color') {
        this.showColor = this.showColor === 10 ? this.filterColor.length : 10
      } else if (target === 'brand') {
        this.showBrand = this.showBrand === 10 ? this.filterBrands.length : 10
      } else if (target === 'size') {
        this.showSize = this.showSize === 10 ? this.filterSize.length : 10
      } else if (target === 'location') {
        this.showLocation = this.showLocation === 10 ? this.filterLocation.length : 10
      }
    },
    checkChange () {
      this.selected_category = this.$refs.categoryTree.getCheckedKeys()
      this.applyFilter()
    },
    applyFilter () {
      const brand = this.selected_merk.join('::')
      const priceLimit = this.valueRange.join('::')
      const category = this.selected_category.join('::')
      const color = this.selected_color.join('::')
      const size = this.selected_size.join('::')
      const location = this.selected_location.join('::')
      const rating =
        this.selected_rating.length > 1 ? this.selected_rating.join('::') : null
      this.$router.push({
        query: {
          ...this.modelPayload(
            brand,
            this.search,
            category,
            color,
            size,
            priceLimit,
            rating,
            this.sort,
            location
          ),
          event_sale: this.$route.query.event_sale
        }
      })
    },
    handleClickCategory (data) {
      this.selected_category = [data.slug]
      this.applyFilter()
    }
  }
}
