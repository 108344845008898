import { render, staticRenderFns } from "./login.vue?vue&type=template&id=6bdb2cfc&scoped=true&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdb2cfc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginForm: require('/usr/src/app/components/auth/LoginForm.vue').default,OnBoardingSection: require('/usr/src/app/components/auth/OnBoardingSection.vue').default,AuthLayoutWrapper: require('/usr/src/app/components/auth/AuthLayoutWrapper.vue').default})
