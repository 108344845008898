import { render, staticRenderFns } from "./NearestStoreSection.vue?vue&type=template&id=339692ed&scoped=true&"
import script from "./NearestStoreSection.vue?vue&type=script&lang=js&"
export * from "./NearestStoreSection.vue?vue&type=script&lang=js&"
import style0 from "./NearestStoreSection.vue?vue&type=style&index=0&id=339692ed&lang=scss&scoped=true&"
import style1 from "./NearestStoreSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339692ed",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NearestStoreItem: require('/usr/src/app/components/home/products/detail/NearestStoreItem.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default})
