//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
import mixins from '@/mixins';

export default {
  mixins: [mixins],

  props: {
    promotion: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),

    getBannerImage () {
      let bannerFile = this.promotion.files.filter((file) => {
        return file.name === 'banner'
      })

      if (bannerFile.length > 0) {
        bannerFile = bannerFile[0].value + '.webp'
      } else {
        bannerFile = ''
      }

      return bannerFile
    },

    onBannerPromotionClicked () {
      if (this.promotion.type !== 'voucher') {
        const type = {
          clearance_sale: 'clearance-sale',
          flash_sale: 'flashsale',
          event_sale: this.promotion.event_sales && this.promotion.event_sales.slug
            ? `sale/${this.promotion.event_sales.slug}`
            : 'sale',
          external: this.promotion.link
        }

        if (this.promotion.type === 'external') {
          window.location.href = this.promotion.link
        } else {
          this.$router.push(`/${type[this.promotion.type]}`)
        }
      } else {
        this.setVoucher(this.promotion.vouchers)
        this.setToggleDetail(true)
        this.$refs.showModalVoucher.click()
      }
    }
  }
}
