//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    layout: 'auth',
    middleware: ['guest'],
    head: () => {
        return {
            title: 'Forgot Password - Paninti Store',
            meta: [{
                hid: 'description',
                name: 'description',
                content: 'Forgot Password Page'
            }]
        }
    }
}
