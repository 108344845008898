//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'ProductRecommendationAffiliate',
  mixins: [panstoreMixin],

  data () {
    return {
      perPage: 8,
      onLoading: true
    }
  },
  computed: {
    ...mapState('Product', ['popularUnAffiliated']),
    listProductPopular () {
    return this.popularUnAffiliated;
  }
  },
  async mounted () {
    await this.handleGetProduct()
  },
  methods: {
    ...mapActions('Product', ['getPopularUnAffiliated', 'addProductAffiliate', 'getPopularAffiliateProduct']),
    async handleGetProduct () {
      try {
        const payload = {
          data: {
            paginated: false,
            limit: 8,
            per_page: this.perPage,
            for_recommendation: true
          }
        }

        await this.getPopularUnAffiliated(payload)
      } catch (error) {
        console.log(error)
      } finally {
        this.onLoading = false
      }
    },
    async handleGetPopularProduct () {
    try {
      const payload = {
        data: {
          paginated: false,
          limit: 4,
          per_page: this.perPage,
          order_by: 'most_popular'
        }
      }

      await this.getPopularAffiliateProduct(payload)
    } catch (error) {}
  },
    async handleAddProductAffiliate (value) {
      const productName = this.popularUnAffiliated.find(item => item.id === value.id).name
      try {
        const resp = await this.addProductAffiliate({
          data: {
            product_ids: [value.id]
          }
        })
        if (resp.data.status_code === 201) {
          this.handleGetProduct()
          this.handleGetPopularProduct()
          this.$message({
            message: `${productName} berhasil ditambahkan sebagai produk afiliasi`,
            type: 'success',
            showClose: true
          })
        }
      } catch (error) {
        this.$message({
          message: `Gagal menambahkan ${productName} sebagai produk afiliasi`,
          type: 'error',
          showClose: true
        })
      }
    }
  }
}
