//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { formatRupiah } from '@/utils/helpers/string-helper'
export default {
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('Product', ['fulfilledProduct']),
    ...mapState('User/address', ['location', 'userAddress']),
    currentLocation () {
      return this.location.value || 'Kota Jakarta'
    }
  },
  watch: {
    location () {
      this.fetchFulfilled()
    }
  },
  async mounted () {
    await this.fetchFulfilled()
  },
  methods: {
    isObjectNotEmptyOrNull,
    formatRupiah,
    ...mapActions('Product', ['getFulfilledDetailProduct']),
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant', 'setIsBuyNow']),
    async fetchFulfilled () {
      try {
        this.loading = true
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155
        await this.getFulfilledDetailProduct({
          slug: this.$route.params.slug,
          params: {
            city_id: cityId
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async handleGetCart () {
      const payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }
      try {
        await this.getCartsNavbar({ params: payload })
      } catch (error) {
        console.log(error)
      }
    },
    async handleAddCart (item, buynow) {
      await this.setSelectedProduct({
        ...item,
        is_fulfilled: true
      })

      this.setIsBuyNow(buynow)

      if (item.type === 'simple') {
        const payload = Object.assign({
          data: {
            is_fulfilled: true,
            store_id: item.store.id,
            product_detail_id: item.details[0].id,
            quantity: 1,
            session_id: this.$cookies.get('session_id')
          }
        })

        try {
          const resp = await this.addCart(payload)
          localStorage.removeItem('cartItem')
          if (resp.data.status_code === 201) {
            if (!buynow) {
              await this.setSelectedVariant(null)
              await this.handleGetCart()
              this.$refs.showAlert.click()
            } else {
              const cartItem = []
              cartItem.push(resp.data.data.cart.id)
              localStorage.cartItem = JSON.stringify(cartItem)

              const route = this.$auth.loggedIn ? '/checkout' : '/login'
              setTimeout(() => {
                this.$router.push(route)
              }, 200)
            }
          } else {
            this.$message({
              showClose: true,
              message: resp.data.message,
              type: 'error'
            })
          }
        } catch (error) {
          console.log(error)
          const errorMessage = error.response
            ? error.response.data.message
            : error
          this.$message({
            showClose: true,
            message: errorMessage,
            type: 'error'
          })
        }
      } else {
        this.$refs.showModal.click()
      }
    },
    handleBuyNow () {
      if (this.$auth.loggedIn) {
        if (
          this.$auth.user.main_address ||
          this.userAddress.length
        ) {
          this.handleAddCart(this.fulfilledProduct, true, event)
        } else {
          this.$Swal
            .fire({
              title: 'Anda belum memiliki alamat',
              text:
                'Silahkan tambah alamat untuk melanjutkan ke proses checkout',
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Tambah alamat'
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$refs.buttonCreateAddress.click()
              }
            })
        }
      } else {
        this.$router.push(`/login?redirect_from=${this.$route.path}`)
      }
    }
  }
}
