import { render, staticRenderFns } from "./ListBankAccount.vue?vue&type=template&id=5370a6db&scoped=true&"
import script from "./ListBankAccount.vue?vue&type=script&lang=js&"
export * from "./ListBankAccount.vue?vue&type=script&lang=js&"
import style0 from "./ListBankAccount.vue?vue&type=style&index=0&id=5370a6db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5370a6db",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,CardStatsBank: require('/usr/src/app/components/account/bank-account/CardStatsBank.vue').default,ModalCreateBankAccount: require('/usr/src/app/components/base/ModalCreateBankAccount.vue').default})
