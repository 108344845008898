//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    classOnboarding: {
      type: String,
      default: 'bg-surface-brand'
    },
    isLogo: {
      type: Boolean,
      default: true
    }
  }
}
