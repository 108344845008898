//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateRandomString } from '@/utils/helpers/string-helper'

export default {
  props: {
    datas: {
      type: Array,
      default: () => []
    },

    mobile: {
      type: Boolean,
      default: () => false
    },

    isSearched: {
      type: Boolean,
      default: () => false
    },

    basePath: {
      type: String,
      default: '/'
    }
  },

  methods: { generateRandomString }
}
