//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions } from 'vuex'
import { swal2Icons, defaultSwal2Options } from '@/utils/helpers/swal2-helper';
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
export default {
  layout: 'account',
  middleware: ['user'],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Kartu Kredit/Debit',
        path: '/account/payment'
      },
      {
        name: 'Tambah Kartu Kredit/Debit'
      }
    ]
    const validateNumberOnly = (rule, value, callback) => {
      const letters = /^[0-9]+$/
      const field = rule.field
      const input = value.charAt(value.length - 1)
      if (value === ' ') {
        this.formData[field] = ''
      }
      if (!input.match(letters)) {
        callback(new Error('Inputan hanya berupa angka'))
        this.formData[field] = value.substring(0, value.length - 1)
      } else {
        callback()
      }
    }
    return {
      breadCrumb: breadcrumbs,
      loading: true,
      onSubmit: false,
      formData: {
        account_number: '',
        expired_month: '',
        expired_year: '',
        card_holder: '',
        cvv: ''
      },
      rules: {
        account_number: [
          { required: true, message: 'Nomor Kartu Kredit / Debit harus diisi', trigger: 'blur' },
          {
            validator: validateNumberOnly
          }
        ],
        expired_month: [
          { required: true, message: 'Masa berlaku harus diisi', trigger: 'blur' },
          {
            validator: validateNumberOnly
          }
        ],
        expired_year: [
          { required: true, message: 'Masa berlaku harus diisi', trigger: 'blur' },
          {
            validator: validateNumberOnly
          }
        ],
        card_holder: [
          { required: true, message: 'Nama pemilik kartu harus diisi', trigger: 'blur' }
        ],
        cvv: [
          { required: true, message: 'CVV harus diisi', trigger: 'blur' },
          {
            validator: validateNumberOnly
          }
        ]
      }
    }
  },
  mounted () {
    window.Xendit.setPublishableKey(process.env.XENDIT_PUBLIC_KEY)
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBank']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    ...mapActions('User', [
      'createPayment',
      'getPayment',
      'createTokenCC',
      'checkValidCC'
    ]),
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
            const expYear = '20' + this.formData.expired_year
          this.onSubmit = true
          try {
            const check = await this.checkValidCC({
              data: {
                credit_card: {
                  card_number: this.formData.account_number
                }
              }
            })
            if (
              check.status === 200 &&
              check.data.data.available_for_new_account
            ) {
              const resAccountNumber = window.Xendit.card.validateCardNumber(this.formData.account_number)
              const resExpiry = window.Xendit.card.validateExpiry(this.formData.expired_month, expYear)
              const resCvn = window.Xendit.card.validateCvn(this.formData.cvv)
              if (!resAccountNumber || !resExpiry || !resCvn) {
                this.$Swal.fire({
                  ...defaultSwal2Options(),
                  icon: swal2Icons.error,
                  title: 'Validasi Credit Card Gagal',
                  text: 'Periksa kembali credit card yang dimasukan'
                })

                return
              }

              const payload = {
                credit_card: {
                  amount: 5000,
                  card_number: this.formData.account_number,
                  card_exp_month: this.formData.expired_month,
                  card_exp_year: expYear,
                  card_cvv: this.formData.cvv,
                  is_multiple_use: false,
                  should_authenticate: true
                }
              }
                try {
                  const resp = await this.createTokenCC(payload.credit_card)
                  if (resp.status === 'IN_REVIEW') {
                    this.setDataAccount({
                      account_number: this.formData.account_number,
                      card_holder: this.formData.card_holder,
                      card_charge_amount: 5000,
                      card_token_id: resp.id,
                      card_authentication_id: resp.authentication_id || null,
                      card_exp_month: this.formData.expired_month,
                      card_exp_year: expYear,
                      card_cvv: this.formData.cvv
                    })
                    this.setLink3DS(
                      resp.payer_authentication_url
                    )
                    if (
                      resp.payer_authentication_url
                    ) {
                      this.$refs.showModal3DS.click()
                    }
                  }
                } catch (error) {
                  console.error(error)
                  this.$Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: getResponseErrorMessage(error),
                    heightAuto: false
                  })
                } finally {
                  this.onSubmit = false
                }
            } else {
              this.$Swal.fire({
                ...defaultSwal2Options(),
                icon: swal2Icons.error,
                title: 'Gagal',
                text: 'Kartu kredit tidak dapat digunakan / telah terdaftar !',
                heightAuto: false
              })
            }
          } catch (error) {
            console.log(error)
            this.$Swal.fire({
              ...defaultSwal2Options(),
              icon: swal2Icons.error,
              title: 'Gagal',
              text: getResponseErrorMessage(error),
              heightAuto: false
            })
          } finally {
            this.onSubmit = false
          }
        }
      })
    }
  }

}
