import { render, staticRenderFns } from "./SidebarAcc.vue?vue&type=template&id=78f0d446&scoped=true&"
import script from "./SidebarAcc.vue?vue&type=script&lang=js&"
export * from "./SidebarAcc.vue?vue&type=script&lang=js&"
import style0 from "./SidebarAcc.vue?vue&type=style&index=0&id=78f0d446&lang=scss&scoped=true&"
import style1 from "./SidebarAcc.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f0d446",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconKindergarten: require('/usr/src/app/components/icons/IconKindergarten.vue').default,IconPanintiCoin: require('/usr/src/app/components/icons/IconPanintiCoin.vue').default,IconChevronRight: require('/usr/src/app/components/icons/IconChevronRight.vue').default,Badge: require('/usr/src/app/components/base/Badge.vue').default,ModalDeleteAccount: require('/usr/src/app/components/account/ModalDeleteAccount.vue').default,ModalShareLink: require('/usr/src/app/components/base/ModalShareLink.vue').default})
