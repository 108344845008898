import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0341b578&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0341b578&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0341b578",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,DetailProductSection: require('/usr/src/app/components/home/products/detail/DetailProductSection.vue').default,StoreSection: require('/usr/src/app/components/home/products/detail/StoreSection.vue').default,NearestStoreSection: require('/usr/src/app/components/home/products/detail/NearestStoreSection.vue').default,InformationProductSection: require('/usr/src/app/components/home/products/detail/InformationProductSection.vue').default,VideoProductSection: require('/usr/src/app/components/home/products/detail/VideoProductSection.vue').default,LorikeetSection: require('/usr/src/app/components/home/products/detail/LorikeetSection.vue').default,ReviewSection: require('/usr/src/app/components/home/products/detail/ReviewSection.vue').default,AlertNotLogin: require('/usr/src/app/components/base/AlertNotLogin.vue').default,FulfilledSection: require('/usr/src/app/components/home/products/detail/FulfilledSection.vue').default,SimilarProduct: require('/usr/src/app/components/home/products/detail/SimilarProduct.vue').default,OtherProduct: require('/usr/src/app/components/home/products/detail/OtherProduct.vue').default,FormCreateAddress: require('/usr/src/app/components/account/address/FormCreateAddress.vue').default,AddToCartBottom: require('/usr/src/app/components/home/products/detail/AddToCartBottom.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default,PopupAddToCart: require('/usr/src/app/components/base/PopupAddToCart.vue').default,ModalShareProduct: require('/usr/src/app/components/home/products/detail/ModalShareProduct.vue').default})
