//
//
//

import cookiesMixin from '@/mixins/cookies-mixin'
export default {
  mixins: [cookiesMixin],
  created () {
    this.setCookies()
  }
}
