//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { formatRupiah } from '@/utils/helpers/string-helper'
export default {
  computed: {
    ...mapState('Product', ['product']),
    ...mapState('User/address', ['location']),
    currentLocation () {
      return this.location.value || 'Kota Jakarta'
    }
  },
  methods: {
    formatRupiah
  }
}
