//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'
import { mapActions } from 'vuex'

import panstoreMixin from '@/mixins'

import { getResponseErrorMessage } from '@/utils/helpers/response-helper'

export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  data () {
    const validateEmail = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const letters = /^[A-Za-z._0-9._+@]+$/
      const field = rule.field

      if (value === ' ') {
        this.formLoginAccount[field] = ''
      }
      if (!input.match(letters)) {
        this.formLoginAccount[field] = value.substr(0, value.length - 1)
      }

      callback();
    }
    const validatePassword = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const field = rule.field

      if (value === ' ') {
        this.formLoginAccount[field] = ''
      }
      if (input === ' ') {
        this.formLoginAccount[field] = value.substr(0, value.length - 1)
      }

      callback();
    }

    return {
      fromRegister: false,
      formLoginAccount: {
        email: '',
        password: '',
        session_id: this.$cookies.get('session_id')
      },
      formLoginAccountRules: {
        email: [
          {
            required: true,
            message: 'Masukan username/email',
            trigger: 'blur'
          },
          {
            validator: validateEmail
          }
        ],
        password: [
          {
            required: true,
            message: 'Masukan kata sandi',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 100,
            message: 'Username minimal 6 karakter',
            trigger: 'blur'
          },
          {
            validator: validatePassword
          }
        ]
      }
    }
  },
  computed: {
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    }
  },
  methods: {
    ...mapActions('Auth', ['sendOtp']),

    async handleSubmitLoginForm () {
      await this.$refs.formLoginAccount.validate(async (valid) => {
        if (valid) {
          try {
            await this.$auth.loginWith('local', {
              data: {
                email: this.formLoginAccount.email,
                password: this.formLoginAccount.password,
                remember: false,
                session_id: this.$cookies.get('session_id')
              }
            })

            this.$cookies.remove('session_id')
            window.location.reload()
          } catch (error) {
            console.log(error)

            await this.$Swal.fire({
              icon: 'error',
              title: 'Gagal Login',
              text: getResponseErrorMessage(error),
              heightAuto: false
            })
          }
        }
      })
    },

    toSocmedPage () {
      if (!this.user.email_verified_at) {
        this.handleSendOtp()
        this.openOtpModal()
      } else {
        this.$router.push({
          query: {
            page: 2
          }
        })
      }
    },

    async handleSendOtp () {
      if (this.$refs.modalOtp.showCountdown) {
        return
      }
      try {
        await this.sendOtp({
          data: {
            purpose: 'registration'
          }
        })
        this.$refs.modalOtp.setCountdown()
        this.$message({
          message: 'Kode OTP Berhasil dikirim, silahkan cek email anda',
          type: 'success',
          showClose: true
        })
      } catch (error) {
        this.$message.error(getResponseErrorMessage(error))
      }
    },

    handleFromRegister () {
      this.fromRegister = true
      this.openOtpModal()
    },

    openOtpModal () {
      this.$refs.btnOpenOtpModal.click()
    },

    async handleLogout () {
      await this.$auth.logout('local')
      this.$cookies.remove('session_id')
      window.location.reload()
    }
  }
}
