//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import { formatRupiah, generateRandomString } from '@/utils/helpers/string-helper'
import { maxNumberOfBankAccount } from '@/utils/helpers/user-helper'
import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { accountTypes } from '@/utils/helpers/account-helper'

export default {
  props: {
    totalCommission: {
      type: Number,
      default: 0
    },

    userBankAccountsData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes()
      })
    },

    createCommissionWithdrawalData: {
      type: Object,
      default: () => ({
        loading: false
      })
    }
  },

  data () {
    return {
      formWithdraw: {
        bankAccount: ''
      },

      getUserBankAccountsData: {
        ...defaultNotPaginationAttributes(),
        params: {
          type: accountTypes.bank
        }
      }
    }
  },

  computed: {
    ...mapState('BankAccount', ['bankAccounts']),

    isUserHasBankAccount () {
      return this.userBankAccountsData.data && this.userBankAccountsData.data.length > 0
    },

    isAmountOfUserBankAccountReachLimit () {
      return this.userBankAccountsData.data ? this.userBankAccountsData.data.length >= maxNumberOfBankAccount : false
    },

    selectedBankWithdrawalAccount () {
      return this.userBankAccountsData.data.find(item => item.id === this.formWithdraw.bankAccount)
    },

    transferFee () {
      if (this.formWithdraw.bankAccount) {
        return this.selectedBankWithdrawalAccount ? this.selectedBankWithdrawalAccount.transfer_fee : 0
      }

      return 0
    },

    totalWithdrawal () {
      return this.totalCommission - this.transferFee
    }
  },

  methods: {
    ...mapActions('BankAccount', ['getBankAccountsWithdrawal']),

    generateRandomString,
    formatRupiah,

    closeModal () {
      this.$refs.modalBase.closeModal()
    },

    setFormWithdrawData (payload) {
      Object.assign(this.formWithdraw, payload)
    },

    handleAddUserBankAccount () {
      if (this.userBankAccountsData.data.length >= maxNumberOfBankAccount) {
        this.$message.error('Anda telah mencapai batas maksimal nomor rekening yang dapat ditambahkan.')
        return
      }

      this.formWithdraw.bankAccount = ''
      setTimeout(() => {
        this.closeModal()
        this.$emit('btn-add-user-bank-account-click')
      }, 300)
    },

    getSelectOptionLabelUserBankAccount (userBankAccount) {
      return `${userBankAccount.value ? userBankAccount.value.account_number : ''} - ${userBankAccount.bank
        ? userBankAccount.bank.name : ''} - ${userBankAccount.owner_name}`
    },

    handleBtnSubmitClick () {
      this.$emit('btn-submit-click', {
        data: {
          account_id: this.formWithdraw.bankAccount,
          amount: this.totalCommission
        }
      })
    }
  }
}
