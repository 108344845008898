import { render, staticRenderFns } from "./index.vue?vue&type=template&id=86373b2a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=86373b2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86373b2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,ListAffiliateProducts: require('/usr/src/app/components/account/affiliate/product-list/ListAffiliateProducts.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default,ModalShareProduct: require('/usr/src/app/components/home/products/detail/ModalShareProduct.vue').default})
