//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'

import { formatRupiah } from '@/utils/helpers/string-helper'
import { isOriginalPriceAndDiscountedPriceEqual, splitProductPrice } from '@/utils/helpers/product-helper'

export default {
  components: { VClamp },

  props: {
    product: {
      type: Object,
      default: () => ({
        price: '0',
        discounted_price: '0'
      })
    }
  },

  computed: {
    discountedPriceArray () {
      return splitProductPrice(this.product.discounted_price)
    },

    originalPriceArray () {
      return splitProductPrice(this.product.price)
    }
  },

  methods: {
    splitProductPrice, formatRupiah, isOriginalPriceAndDiscountedPriceEqual
  }
}
