//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    minHeight: {
      type: String,
      default: '520px'
    },
    illustrationUrl: {
      type: String,
      default: () => null
    },
    illustrationWidth: {
      type: String,
      default: () => null
    },
    illustrationHeight: {
      type: String,
      default: () => null
    },
    title: {
      type: String,
      default: () => null
    },
    description: {
      type: String,
      default: () => null
    }
  }
}
