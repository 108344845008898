//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import mixins from '@/mixins';

export default {
  mixins: [mixins],
  layout: 'account',
  middleware: ['user'],

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Beranda',
          path: '/account/home'
        },
        {
          name: 'Terakhir Dibeli'
        }
      ],

      lastPurchasedProducts: {
        isLoading: true,
        page: 1,
        perPage: 8,
        total: 0,
        totalPage: 0,
        data: []
      }
    }
  },

  mounted () {
    this.getLastPurchasedProducts()
  },

  methods: {
    ...mapActions('Product', ['getProductNotReviewed']),

    async handleInfiniteLoading ($state) {
      await this.getLastPurchasedProducts()

      if (this.lastPurchasedProducts.page > this.lastPurchasedProducts.totalPage) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },

    async getLastPurchasedProducts () {
      this.lastPurchasedProducts.isLoading = true

      try {
        const resp = await this.getProductNotReviewed({
          data: {
            page: this.lastPurchasedProducts.page,
            per_page: this.lastPurchasedProducts.perPage,
            order_by: {
              'order_products.created_at': 'desc'
            }
          }
        })

        this.lastPurchasedProducts.total = resp.data.data.order_products.total
        this.lastPurchasedProducts.totalPage = resp.data.data.order_products.last_page
        this.lastPurchasedProducts.data = [
          ...this.lastPurchasedProducts.data,
          ...resp.data.data.order_products.data
        ]
        this.lastPurchasedProducts.page++
      } catch (error) {
        this.$message.error('Gagal mendapatkan data produk terakhir dibeli')
      }

      this.lastPurchasedProducts.isLoading = false
    }
  }
}
