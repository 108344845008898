//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  mixins: [panstoreMixin],

  props: {
    item: {
      type: Object,
      default: () => {}
    },

    productSale: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      value: 4.5,
      colors: ['#FFCF44', '#FFCF44', '#FFCF44'],
      iconReview: '/img/icon/card-product/icon-review.svg',
      iconWishlist: '/img/icon/card-product/icon-wishlist.svg',
      iconRegistry: '/img/icon/card-product/icon-registry.svg',
      iconBuy: '/img/icon/card-product/icon-buy.svg',
      is_wishlisted: this.item.is_wishlisted,
      urlOnlyEvent: ['/clearance-sale', '/flashsale', '/sale/']
    }
  },

  computed: {
    showDisc () {
      if (this.item.type === 'simple') {
        return this.item.price !== this.item.discounted_price
      } else {
        return (
          this.item.discounted_price.split('-').length === 1 &&
          this.item.price !== this.item.discounted_price
        )
      }
    },

    onFlashsale () {
      if (this.item.details[0].event_sale) {
        return this.getFlashTime(this.item.details[0].event_sale.date_start)
      } else {
        return false
      }
    },

    eventExist () {
      let eventExist = false
      const eventRes = []

      Object.keys(this.item.event_ongoing).forEach((key, index) => {
        eventRes.push(this.item.event_ongoing[key].length !== 0)
      })

      eventExist = eventRes.includes(true)

      return eventExist
    },

    getImageOriginalCard () {
      let images = []

      const checkUrl = this.urlOnlyEvent.some((url) => {
        return this.$route.path.includes(url)
      })

      if (this.eventExist && this.item.type === 'combination' && checkUrl) {
        const mainDetail = this.item.details.filter((detail) => {
          return detail.main === true
        })

        images = mainDetail && mainDetail.length ? mainDetail[0].files : this.item.details[0].files
      } else {
        images = this.item.files
      }

      return this.checkArray(images) ? images : []
    },

    totalSoldText () {
      let soldText = this.item.total_sold > 0 ? `Terjual ${this.item.total_sold}` : 'Belum Terjual'

      if (this.item.is_preorder) {
        soldText = this.item.total_preorder > 0 ? `${this.item.total_preorder} Dipesan` : 'Belum Dipesan'
      }

      return soldText
    }
  },

  methods: {
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapActions('Product', ['getProductWishlist']),

    async handleAddCart (item) {
      await this.setSelectedProduct(item)

      if (item.type === 'simple') {
        const payload = Object.assign({
          data: {
            product_detail_id: item.details[0].id,
            quantity: 1,
            session_id: this.$cookies.get('session_id')
          }
        })

        try {
          const resp = await this.addCart(payload)

          if (resp.data.status_code === 201) {
            await this.setSelectedVariant(null)
            await this.handleGetCart()
            this.$refs.showAlert.click()
          } else {
            this.$message({
              showClose: true,
              message: resp.data.message,
              type: 'error'
            })
          }
        } catch (error) {
          console.log(error)
          const errorMessage = error.response
            ? error.response.data.message
            : error
          this.$message({
            showClose: true,
            message: errorMessage,
            type: 'error'
          })
        }
      } else {
        this.$refs.showModal.click()
      }
    },

    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }

      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })

        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }

      try {
        await this.getCartsNavbar({
          params: {
            session_id: payload.session_id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    hoverIconReview (hover) {
      if (hover) {
        this.iconReview = '/img/icon/card-product/icon-review-filled.svg'
      } else {
        this.iconReview = '/img/icon/card-product/icon-review.svg'
      }
    },

    hoverIconWishlist (hover) {
      if (!this.is_wishlisted) {
        if (hover) {
          this.iconWishlist = '/img/icon/card-product/icon-wishlist-filled.svg'
        } else {
          this.iconWishlist = '/img/icon/card-product/icon-wishlist.svg'
        }
      }
    },

    hoverIconRegistry (hover) {
      if (hover) {
        this.iconRegistry = '/img/icon/card-product/icon-registry-filled.svg'
      } else {
        this.iconRegistry = '/img/icon/card-product/icon-registry.svg'
      }
    },

    hoverIconBuy (hover) {
      if (hover) {
        this.iconBuy = '/img/icon/card-product/icon-buy-filled.svg'
      } else {
        this.iconBuy = '/img/icon/card-product/icon-buy.svg'
      }
    },

    getFlashTime (start) {
      const startTime = new Date(this.formatYMD(start))
      if (startTime.getTime() < new Date().getTime()) {
        return true
      } else {
        return false
      }
    },

    async handleToggleWishlist () {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_id: [this.item.id]
            }
          })

          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })

            this.iconWishlist =
              this.iconWishlist ===
              '/img/icon/card-product/icon-wishlist-filled.svg'
                ? '/img/icon/card-product/icon-wishlist.svg'
                : '/img/icon/card-product/icon-wishlist-filled.svg'

            this.is_wishlisted = !this.is_wishlisted

            this.$message({
              message: `Produk berhasil ${
                !this.is_wishlisted ? 'dihapus dari ' : 'ditambahkan ke'
              } Wishlist.`,
              type: 'success',
              showClose: true
            })
          }
        } catch (error) {
          console.log(error)
          this.$message({
            message: 'Oops, Terjadi kesalahan.',
            type: 'error',
            showClose: true
          })
        }
      }
    },

    goToReview (slug) {
      this.$router.push(`/product/${slug}#review`)
    },

    getSortedFiles (files) {
      let dataFiles = JSON.parse(JSON.stringify(files))

      dataFiles = dataFiles.filter((file) => {
        return file.variant === 'thumbnail'
      })

      dataFiles = dataFiles.sort((a, b) => {
        return a.sequence - b.sequence
      })

      return dataFiles.length ? dataFiles : []
    }
  }
}
