import { render, staticRenderFns } from "./account.vue?vue&type=template&id=83768112&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"
import style0 from "./account.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LittleBanner: require('/usr/src/app/components/base/LittleBanner.vue').default,Header: require('/usr/src/app/components/home/base/Header.vue').default,Navbar: require('/usr/src/app/components/home/base/Navbar.vue').default,SidebarAcc: require('/usr/src/app/components/account/SidebarAcc.vue').default,MainSidebar: require('/usr/src/app/components/home/base/MainSidebar.vue').default,PopupAddToCart: require('/usr/src/app/components/base/PopupAddToCart.vue').default,AlertAddToCart: require('/usr/src/app/components/base/AlertAddToCart.vue').default,BottomNavigation: require('/usr/src/app/components/base/BottomNavigation.vue').default,AlertNotLogin: require('/usr/src/app/components/base/AlertNotLogin.vue').default,PopupLocation: require('/usr/src/app/components/base/PopupLocation.vue').default})
