//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { isArrayNotNullOrEmpty } from '~/utils/helpers/array-helper'

export default {
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      default: () => {}
    }
  },

  data () {
    return {
      stepText: {
        new: 'Pengajuan Dibuat',
        approved: 'Pengajuan Diterima',
        rejected: 'Pengajuan Ditolak',
        done: 'Pengajuan Selesai'
      },
      visible: false,
      indexImg: 0
    }
  },

  computed: {
    imgPreview () {
      return this.files.map((file) => {
        return file.value
      })
    }
  },

  methods: {
    isArrayNotNullOrEmpty,
    formatDateWithTimezone,

    openPaymentProofImage () {
      this.visible = true
    }
  }
}
