//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import _ from 'lodash'

import authMixin from '@/mixins/auth-mixin'

import { generateRandomString } from '@/utils/helpers/string-helper'
import { listUnaffiliatedProductsStoreTypesFilter } from '@/utils/helpers/affiliate-product-helper'
import { getStoreBadgeFromStoreType, storeTypes } from '@/utils/helpers/store-helper'
import { homePagePaths } from '@/utils/helpers/page-helper'
import {
  assignResponseToDataModelData,
  defaultPaginationAttributes,
  resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'
import { getResponseErrorMessage, isResponseInternalServerError } from '@/utils/helpers/response-helper'
import { toastTypes } from '@/utils/helpers/toast-helper'

export default {
  layout: 'account',
  middleware: ['user', 'affiliate'],

  mixins: [
    authMixin
  ],

  data () {
    return {
      breadCrumb: [
        {
          path: homePagePaths.account
        },
        {
          name: 'Afiliasi',
          path: '/account/affiliate/'
        },
        {
          name: 'Daftar Produk Afiliasi',
          path: '/account/affiliate/product-list/'
        },
        {
          name: 'Tambah Produk Afiliasi'
        }
      ],

      listUnaffiliatedProductsFilter: {
        search: '',
        storeType: listUnaffiliatedProductsStoreTypesFilter.all.value
      },

      getUnaffiliatedProductsData: {
        ...defaultPaginationAttributes(),
        perPage: 8
      }
    };
  },

  computed: {
    listUnaffiliatedProductsStoreTypesFilter () {
      return listUnaffiliatedProductsStoreTypesFilter
    }
  },

  watch: {
    '$route.query': {
      handler (query) {
        this.resetUnaffiliatedProductData()
      },
      deep: true
    }
  },

  mounted () {
    this.setUnaffiliatedProductDataFromRoute()
  },

  methods: {
    ...mapActions('Product', ['getProductUnAffiliated', 'addProductAffiliate']),

    getStoreBadgeFromStoreType,
    generateRandomString,

    setUnaffiliatedProductDataFromRoute () {
      this.listUnaffiliatedProductsFilter.search = this.$route.query.unaffiliatedProductSearch || ''
      this.listUnaffiliatedProductsFilter.storeType =
        this.$route.query.unaffiliatedProductStoreType || listUnaffiliatedProductsStoreTypesFilter.all.value
    },

    resetUnaffiliatedProductData () {
      this.$refs.catalogListUnaffiliatedProducts.completeInfiniteLoadingListUnaffiliatedProducts()

      resetPaginationData(this.getUnaffiliatedProductsData, {
        perPage: this.getUnaffiliatedProductsData.perPage
      })

      this.$refs.catalogListUnaffiliatedProducts.resetInfiniteLoadingListUnaffiliatedProducts()
    },

    // Filter List Unaffiliated Products
    handleChangeInputSearch: _.debounce(function (searchValue) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          unaffiliatedProductSearch: searchValue || null
        })
      })
    }, 700),

    isTabFilterHasBadge (tabValue) {
      return Object.values(storeTypes).map((storeType) => {
        return storeType.value
      })
        .includes(tabValue)
    },

    handleClickFilterStoreType (storeType) {
      this.listUnaffiliatedProductsFilter.storeType = storeType.value

      this.$router.push({
        query: {
          ...this.$route.query,
          unaffiliatedProductStoreType: this.listUnaffiliatedProductsFilter.storeType
        }
      })
    },

    // Catalog List Unaffiliated Products
    async handleInfiniteLoadingGetUnaffiliatedProducts ($state) {
      if (this.getUnaffiliatedProductsData.page >= this.getUnaffiliatedProductsData.totalPage) {
        $state.complete()
        return
      }

      await this.getUnaffiliatedProducts()
      $state.loaded()
    },

    async getUnaffiliatedProducts () {
      try {
        this.getUnaffiliatedProductsData.loading = true

        const response = await this.getProductUnAffiliated({
          data: {
            paginate: this.getUnaffiliatedProductsData.paginated,
            per_page: this.getUnaffiliatedProductsData.perPage,
            page: this.getUnaffiliatedProductsData.page + 1,
            ...filterNonNullValues({
              search: this.listUnaffiliatedProductsFilter.search || null,
              store_type: this.listUnaffiliatedProductsFilter.storeType !== listUnaffiliatedProductsStoreTypesFilter.all.value
                ? this.listUnaffiliatedProductsFilter.storeType
                : null
            })
          }
        })

        assignResponseToDataModelData(
          this.getUnaffiliatedProductsData,
          response.data.data.products,
          false
        )
      } catch (error) {
        console.log(error)
        this.$refs.catalogListUnaffiliatedProducts.completeInfiniteLoadingListUnaffiliatedProducts()

        this.$message({
          message: 'Gagal memuat data produk',
          showClose: true,
          type: toastTypes.error.value
        })
      } finally {
        this.getUnaffiliatedProductsData.loading = false
      }
    },

    async handleAddProductToAffiliate (payload) {
      try {
        await this.addProductAffiliate({
          data: {
            product_ids: [payload.id]
          }
        })

        this.$message({
          message: `Produk ${payload.productName} berhasil ditambahkan sebagai produk afiliasi`,
          showClose: true,
          type: toastTypes.success.value
        })
        await this.resetUnaffiliatedProductData()
      } catch (error) {
        console.log(error)

        this.$message({
          message: isResponseInternalServerError(error)
            ? `Gagal menambahkan ${payload.productName} sebagai produk afiliasi`
            : getResponseErrorMessage(error),
          showClose: true,
          type: toastTypes.error.value
        })
      }
    }
  }
}
