var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalog-list-affiliate-products"},[_c('div',{staticClass:"products-container"},_vm._l((_vm.affiliateProductsData.data),function(affiliateProduct){return _c('CardProduct',{key:'card-product-' + _vm.generateRandomString(4) + '-' + affiliateProduct.id,attrs:{"item":affiliateProduct.product,"affiliate-prop":{
        id: affiliateProduct.id,
        share_url: affiliateProduct.share_url,
        total_click: affiliateProduct.total_click,
        total_order: affiliateProduct.total_order,
        updated_at: affiliateProduct.updated_at
      },"purpose":_vm.cardProductPurposes.affiliate,"show-share":"","show-estimated-affiliate":""},on:{"delete-affiliate-item":_vm.handleDeleteAffiliateItem,"btn-share-clicked":_vm.handleBtnShareClicked}})}),1),_vm._v(" "),_c('client-only',[_c('infinite-loading',{ref:"infiniteLoadingListAffiliateProducts",on:{"infinite":_vm.handleInfiniteLoadingListAffiliateProducts}},[_c('div',{staticClass:"products-container",class:{
          'paninti-mt-spacing-m': _vm.affiliateProductsData.page > 0
        },attrs:{"slot":"spinner"},slot:"spinner"},_vm._l((_vm.affiliateProductsData.perPage),function(i){return _c('ProductCardSkeletonLoading',{key:'card-product-loading-' + _vm.generateRandomString(4) + '-' + i,attrs:{"is-animated":true}})}),1),_vm._v(" "),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_vm._v(" "),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1),_vm._v(" "),(_vm.affiliateProductsData.data.length <= 0 && _vm.affiliateProductsData.loading === false)?_c('EmptyState',{attrs:{"min-height":'520px',"illustration-url":'/img/illustration/no-product.svg',"title":"Belum ada produk afiliasi","description":"Tambahkan produk afiliasimu dan bagikan sebanyak-banyaknya"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }