//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    const validatorEmail = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const letters = /^[A-Za-z0-9._+@]+$/
      if (value === ' ') {
        this.formForgot.email = ''
      }

      if (value.length > 100) {
        this.formForgot.email = value.substring(0, value.length - 1)
      }

      if (!input.match(letters)) {
        this.formForgot.email = value.substring(0, value.length - 1)
      }

      callback()
    }
    return {
      onSubmit: false,
      formForgot: {
        email: ''
      },
      rules: {
        email: [
          { required: true, message: 'Email wajib diisi', trigger: 'blur' },
          { type: 'email', message: 'Format email tidak valid', trigger: 'blur' },
          { validator: validatorEmail }
        ]
      }
    }
  },
  computed: {
    user() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    }
  },
  methods: {
    ...mapActions('Auth', ['reqForgotPassword']),

    async submit() {
      this.onSubmit = true;
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const resp = await this.reqForgotPassword({
              data: {
                email: this.formForgot.email.trim()
              }
            });
            if (resp.status === 200) {
              this.$Swal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: 'Kami telah mengirimkan tautan ke email Anda, periksa email untuk mengatur ulang password!',
                heightAuto: false
              });
            }
          } catch (error) {
            this.$Swal.fire({
              icon: 'error',
              title: 'Gagal',
              text: error.response.data.message,
              heightAuto: false
            });
          }
        }
      });
      this.onSubmit = false;
    },
  },
};
