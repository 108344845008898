//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    const validatorName = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[A-Za-z. ]+$/
      if (value.length === 1) {
        if (input === ' ') {
          this.formRegister.full_name = ''
        } else {
          this.formRegister.full_name = input.toUpperCase()
        }
      }
      if (!input.match(letters)) {
        // hanya huruf, . dan spasi (tidak bisa double spasi)
        this.formRegister.full_name = value.substr(0, value.length - 1)
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formRegister.full_name = value.substr(0, value.length - 1)
      } else if (inputBefore.match(' ')) {
        this.formRegister.full_name =
          value.substr(0, value.length - 1) + input.toUpperCase()
      }
      if (value.length > 100) {
        this.formRegister.full_name = value.substr(0, value.length - 1)
      }
      callback()
    }
    const validatorUsername = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const letters = /^[a-zA-z._0-9]+$/
      if (value === ' ') {
        this.formRegister.username = ''
      }
      if (input === ' ') {
        this.formRegister.username = value.substring(0, value.length - 1)
      }
      if (value.length > 30) {
        this.formRegister.username = value.substring(0, value.length - 1)
      }
      if (!input.match(letters)) {
        this.formRegister.username = value.substr(0, value.length - 1)
      }
      callback()
    }
    const validatorNumberPhone = (rule, value, callback) => {
      const input = value.charAt(
        value.length - 1
      )
      if (
        value.length === 1 &&
        (input === '0' || input === 0)
      ) {
        this.formRegister.phone_number = '62'
      }
      const letters = /[^0-9]/g
      if (letters.test(input)) {
        this.formRegister.phone_number = value.substr(
          0,
          value.length - 1
        )
      }
      if (value.split('').length >= 15) {
        this.formRegister.phone_number = value.substr(
          0,
          value.length - 1
        )
      }
      callback()
    }
    const validatorEmail = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)

      /* eslint-disable */
      const letters = /^[A-Za-z0-9._+@]+$/
      if (value === ' ') {
        this.formRegister.email = ''
      }

      if (value.length > 100) {
        this.formRegister.email = value.substring(0, value.length - 1)
      }

      if (!input.match(letters)) {
        this.formRegister.email = value.substring(0, value.length - 1)
      }
      callback()
    }
    const validatorPassword = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

      if (!value.match(regex)) {
        callback(new Error('Password minimal berisi 1 huruf kapital dan 1 angka'))
      }
      if (value === ' ') {
        this.formRegister.password = ''
      }
      if (input === ' ') {
        this.formRegister.password = value.substr(0, value.length - 1)
      }
      callback()
    }
    return {
      options: [{
        value: 'male',
        label: 'Laki - Laki'
      }, {
        value: 'female',
        label: 'Perempuan'
      }],
      onSubmit: false,
      formRegister: {
        full_name: '',
        date_birth: '',
        gender: '',
        username: '',
        email: '',
        phone_number: '',
        password: '',
        password_confirmation: '',
        remember: false
      },
      rules: {
        full_name: [
          { required: true, message: 'Nama Lengkap wajib diisi', trigger: 'blur' },
          { min: 2, message: 'Nama Lengkap minimal 2 karakter', trigger: 'blur' },
          { validator: validatorName }
        ],
        date_birth: [
          { required: true, message: 'Tanggal lahir wajib diisi', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: 'Jenis kelamin wajib diisi', trigger: 'blur' }
        ],
        username: [
          { required: true, message: 'Username wajib diisi', trigger: 'blur' },
          { min: 6, message: 'Username minimal 6 karakter', trigger: 'blur' },
          { validator: validatorUsername }
        ],
        email: [
          { required: true, message: 'Email wajib diisi', trigger: 'blur' },
          { type: 'email', message: 'Format email tidak valid', trigger: 'blur' },
          { validator: validatorEmail }
        ],
        phone_number: [
          { required: true, message: 'Nomor telepon wajib diisi', trigger: 'blur' },
          { min: 8, message: 'Nomor telepon minimal 8 karakter', trigger: 'blur' },
          { validator: validatorNumberPhone }
        ],
        password: [
          { required: true, message: 'Kata sandi wajib diisi', trigger: 'blur' },
          { min: 6, message: 'Kata sandi minimal 6 karakter', trigger: 'blur' },
          { validator: validatorPassword }
        ],
        password_confirmation: [
          { required: true, message: 'Konfirmasi kata sandi wajib diisi', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.formRegister.password) {
                callback(new Error('Kata sandi tidak sesuai'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('Auth', ['register']),
    submit() {
      this.onSubmit = true
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const payload = {
            data: {
              ...this.formRegister
            }
          }
          const arrFullnames = this.formRegister.full_name.split(' ')
          payload.data.first_name = arrFullnames[0]
          payload.data.last_name = arrFullnames.length > 1 ? arrFullnames[1] : null
          delete payload.data.full_name
          this.register(payload)
            .then((response) => {
              if (response.status === 201) {
                this.handleLogin(
                  this.formRegister.username,
                  this.formRegister.email,
                  this.formRegister.password
                )
              } else {
                this.onSubmit = false
                this.$Swal.fire({
                  icon: 'error',
                  title: 'Gagal',
                  text: response.data.message,
                  heightAuto: false
                })
              }
            })
            .catch((error) => {
              this.onSubmit = false
              if (error.response) {
                this.$Swal.fire({
                  icon: 'error',
                  title: 'Gagal',
                  text: error.response.data.message,
                  heightAuto: false
                })
              } else {
                this.$Swal.fire({
                  icon: 'error',
                  title: 'Gagal',
                  text: error,
                  heightAuto: false
                })
              }
            })
        } else {
          this.onSubmit = false
          this.$Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: 'Lengkapi data dengan benar!',
            heightAuto: false
          })
        }
      })
    },

    checkEmail() {
      const value = this.formRegister.email
      /* eslint-disable */
      const format =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((-\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]{2,})+[a-zA-Z]{2,}))/
      this.errorEmail = ''
      if (value === '') {
        this.errorEmail = 'Email wajib diisi'
      } else if (!format.test(value)) {
        this.errorEmail = 'Format email tidak valid'
      } else {
        this.errorEmail = ''
      }
      if (value.length > 100) {
        this.formRegister.email = value.substring(0, 100)
      }
    },

    async handleLogin(username, email, password) {
      try {
        await this.$auth.loginWith('local', {
          data: {
            email,
            username,
            password,
            session_id: this.$cookies.get('session_id')
          }
        })

        if (this.$route.query.redirect_from) {
          if (this.$route.query.redirect_from === '/cart') {
            this.$Swal.fire({
              icon: 'success',
              title: 'Berhasil Masuk',
              text: 'Selamat datang kembali di Paninti App'
            }).then(() => {
              this.$router.push(this.$route.query.redirect_from)
            })
          } else {
            this.$router.push(this.$route.query.redirect_from)
          }
        } else {
          ``
          window.location.reload()
        }
      } catch (error) {
        console.log(error)
        const errorMessage = error.response ? error.response.data.message : error
        this.$Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: errorMessage
        })
      }
    }
  }
}
