//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { socialMedias } from '@/utils/helpers/social-media-helper'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },

    code: {
      type: String,
      default: ''
    },

    link: {
      type: String,
      default: ''
    }
  },

  computed: {
    socialMedias () {
      return socialMedias
    },

    hasIconSlot () {
      return !!this.$slots.icon
    }
  }
}
