//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIconNotif } from '@/utils/helpers/notif-helper'
export default {
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getIconNotif,
    handleClickNotif (item) {
      this.$emit('click-notif', item)
    }
  }
}
