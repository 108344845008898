import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1b64beb7&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=1b64beb7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b64beb7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormLoginAffiliate: require('/usr/src/app/components/affiliate/create-affiliate/FormLoginAffiliate.vue').default,FormSocialMediaAffiliate: require('/usr/src/app/components/affiliate/create-affiliate/FormSocialMediaAffiliate.vue').default,FormTncAffiliate: require('/usr/src/app/components/affiliate/create-affiliate/FormTncAffiliate.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default})
