//
//
//
//
//
//
//
//
//

export default {
  layout: 'account',
  middleware: ['user', 'affiliate'],
  data () {
    return {
      showDashboard: false,
      breadCrumb: [
        {
          path: '/account/affiliate'
        },
        {
          name: 'Afiliasi'
        }
      ]
    }
  },
  computed: {
    showWelcomePage () {
      return this.$auth.user.affiliate ? this.$auth.user.affiliate.is_new : false;
    }
  },
  async mounted () {
    await this.$auth.fetchUser()
  },
  methods: {
    handleButtonClick () {
      this.showDashboard = true;
    }
  }
}
